import React, { useState, useRef, useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

interface CountdownProps {
  endTime: number;
  onTimeEnd?: () => void;
}

const twoDP = (n: number) => (n > 9 ? n : '0' + n);
const timeOut = '00:00';
const interval = 1000;

export const Countdown = ({ endTime, onTimeEnd }: CountdownProps) => {
  const [time, setTime] = useState<string>(timeOut);
  const intervalIDRef = useRef<any>();

  const stopTimer = useCallback(() => {
    clearInterval(intervalIDRef.current);
    intervalIDRef.current = null;
  }, []);

  const startTimer = useCallback(() => {
    if (endTime) {
      let duration = dayjs.duration(endTime * 1000, 'milliseconds');

      intervalIDRef.current = setInterval(() => {
        duration = dayjs.duration(
          duration.asMilliseconds() - interval,
          'milliseconds'
        );

        if (duration.seconds() < 0) {
          console.log(duration.seconds());

          setTime(timeOut);
          stopTimer();
          onTimeEnd && onTimeEnd();
          return;
        }
        const timestamp = `${twoDP(duration.minutes())}:${twoDP(
          duration.seconds()
        )}`;
        setTime(timestamp);
      }, 1000);
    } else {
      setTime(timeOut);
    }
  }, [endTime, stopTimer]);

  useEffect(() => {
    if (intervalIDRef.current) {
      stopTimer();
    }
    startTimer();
  }, [startTimer, stopTimer, endTime]);

  useEffect(() => {
    return () => {
      setTime(timeOut);
      stopTimer();
    };
  }, [stopTimer]);

  return <>{time}</>;
};
