import React, { useState } from 'react';
import SmartOtpGuideModal from 'components/modal/SmartOtpGuideModal';

export const SmartOtpGuideButton = () => {
  const [isShow, setIsShow] = useState(false);

  return (
    <>
      <button
        className="text text-primary text-xs"
        onClick={() => setIsShow(true)}
      >
        Làm thế nào lấy mã Smart OTP?
      </button>
      <SmartOtpGuideModal isOpen={isShow} closeModal={() => setIsShow(false)} />
    </>
  );
};
