import React, { Fragment, useState } from 'react';
import {
  HamburgerIcon,
  HomeIcon,
  MarketIcon,
  HammerIcon,
  OrderBookIcon,
  WalletIcon,
  ChartIcon,
  VndBoxIcon
} from 'assets/icons/index';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import {
  RiAccountBoxFill,
  RiAccountBoxLine,
  RiAccountCircleFill,
  RiCoinsFill,
  RiExchangeFill,
  RiFileListFill,
  RiLockPasswordFill,
  RiMailFill,
  RiMailLockFill,
  RiMoneyDollarCircleFill,
  RiPhoneFill,
  RiSettings5Fill,
  RiTimeFill
} from 'react-icons/ri';
import { SettingItem } from 'components/SettingItem';
import {
  ChangePasswordModel,
  EmailSupportModel,
  NoAccount,
  RequestCompleteAccountModal
} from 'components/index';
import ChangePinModel from 'components/changePinModel/ChangePinModel';
import { PhoneSupportModel } from 'components/index';
import { AiFillCreditCard, AiFillPieChart } from 'react-icons/ai';
import { useAccount } from 'core/contexts';
import { EKYC } from 'core/constant';

const itemSideBarStyle =
  'h-[70px] w-[75px] flex flex-col gap-[6px] items-center justify-center text-gray text-center cursor-pointer text-xs font-normal leading-4';

const activeStyle = 'bg-tab_background text-primary';

const SideBar = () => {
  const pathName = useLocation().pathname;
  const [isShowSetting, setIsShowSetting] = useState(false);
  const [isShowChangePasswordModal, setIsShowChangePasswordModal] =
    useState(false);
  const [isShowCompleteAccountModal, setIsShowCompleteAccountModal] =
    useState(false);
  const [isShowChangePinModal, setIsShowChangePinModal] = useState(false);
  const [isShowPhoneSupportModal, setIsShowPhoneSupportModal] = useState(false);
  const [isShowEmailSupportModal, setIsShowEmailSupportModal] = useState(false);
  const { account } = useAccount();

  return (
    <div className="bg-black fixed left-0 top-[70px] bottom-[28px] pt-4 pb-10 z-50">
      <div className="max-h-full overflow-y-auto">
        <Link to={'/market'}>
          <div className={itemSideBarStyle}>
            <MarketIcon
              active={
                (!isShowSetting && pathName === '/market') ||
                pathName === '/market/market-movements'
              }
            />
            <p
              className={`${
                (!isShowSetting && pathName === '/market') ||
                pathName === '/market/market-movements'
                  ? 'text-primary font-semibold'
                  : ''
              }`}
            >
              Thị trường
            </p>
          </div>
        </Link>
        <Link to={'/stock-order'}>
          <div className={itemSideBarStyle}>
            <HammerIcon
              active={!isShowSetting && pathName === '/stock-order'}
            />
            <span
              className={`${
                !isShowSetting && pathName === '/stock-order'
                  ? 'text-primary font-semibold'
                  : ''
              }`}
            >
              Đặt lệnh
            </span>
          </div>
        </Link>
        {/* <Link to={'/order-book'}>
        <div className={itemSideBarStyle}>
          <OrderBookIcon
            color={`${
              !isShowSetting && pathName === '/order-book' ? '#0BA5EC' : ''
            }`}
          />
          <p
            className={`${
              !isShowSetting && pathName === '/order-book' ? 'text-primary' : ''
            }`}
          >
            Sổ lệnh
          </p>
        </div>
      </Link> */}
        <Link to={'/'}>
          <div className={itemSideBarStyle}>
            <WalletIcon active={!isShowSetting && pathName === '/'} />
            <p
              className={`${
                !isShowSetting && pathName === '/'
                  ? 'text-primary font-semibold'
                  : ''
              }`}
            >
              Danh mục
            </p>
          </div>
        </Link>

        {/* <Link to={'/margin'}>
        <div className={itemSideBarStyle}>
          <WalletIcon color={`${pathName === '/margin' ? '#0BA5EC' : ''}`} />
          <p className={`${pathName === '/margin' ? 'text-primary' : ''}`}>
            Ký quỹ
          </p>
        </div>
      </Link> */}
        <Menu as="div">
          <Menu.Button>
            <div className={itemSideBarStyle}>
              <VndBoxIcon
                active={
                  !isShowSetting &&
                  (pathName === '/cash-history' ||
                    pathName === '/cash' ||
                    pathName === '/withdraw' ||
                    pathName === '/transfer-cash' ||
                    pathName === '/advance-cash' ||
                    pathName === '/deposit')
                }
              />
              <p
                className={`${
                  !isShowSetting &&
                  (pathName === '/cash-history' ||
                    pathName === '/cash' ||
                    pathName === '/withdraw' ||
                    pathName === '/transfer-cash' ||
                    pathName === '/advance-cash' ||
                    pathName === '/deposit')
                    ? 'text-primary  font-semibold'
                    : ''
                }`}
              >
                Tiền
              </p>
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className="absolute text-base left-[75px] z-[90] top-[220px] w-[192px] bg-black py-4 text-text_main_dark border border-solid border-tab_background font-semibold leading-[22px]">
              <ul className="flex flex-col gap-2">
                <Link
                  to={`/cash`}
                  className={`${pathName === '/cash' ? activeStyle : ''} px-4`}
                >
                  <li className="flex items-center gap-2 my-2">
                    <AiFillPieChart className="text-2xl" />
                    <span className="flex-1">Tổng quan</span>
                  </li>
                </Link>
                <Link
                  to={`/deposit`}
                  className={`${
                    pathName === '/deposit' ? activeStyle : ''
                  } px-4`}
                  onClick={() => {
                    setIsShowSetting(false);
                  }}
                >
                  <li className="flex items-center gap-2 my-2">
                    <AiFillCreditCard className="text-2xl" />
                    <span className="flex-1">Nạp tiền</span>
                  </li>
                </Link>
                <Link
                  to={`/withdraw`}
                  className={`${
                    pathName === '/withdraw' ? activeStyle : ''
                  } px-4`}
                >
                  <li className="flex items-center gap-2 my-2">
                    <RiCoinsFill className="text-2xl" />
                    <span className="flex-1">Rút tiền</span>
                  </li>
                </Link>
                <Link
                  to={`/transfer-cash`}
                  className={`${
                    pathName === '/transfer-cash' ? activeStyle : ''
                  } px-4`}
                >
                  <li className="flex items-center gap-2 my-2">
                    <RiExchangeFill className="text-2xl" />
                    <span className="flex-1">Chuyển tiền</span>
                  </li>
                </Link>
                <Link
                  to={`/advance-cash`}
                  className={`${
                    pathName === '/advance-cash' ? activeStyle : ''
                  } px-4`}
                >
                  <li className="flex items-center gap-2 my-2">
                    <RiMoneyDollarCircleFill className="text-2xl" />
                    <span className="flex-1">Ứng tiền</span>
                  </li>
                </Link>
                <Link
                  to={`/cash-history`}
                  className={`${
                    pathName === '/cash-history' ? activeStyle : ''
                  } px-4`}
                >
                  <li className="flex items-center gap-2 my-2">
                    <RiFileListFill className="text-2xl" />
                    <span className="flex-1">Sao kê tiền</span>
                  </li>
                </Link>
              </ul>
            </div>
          </Transition>
        </Menu>
        {/* <Link to={'/profit'}>
          <div className={itemSideBarStyle}>
            <ChartIcon
              color={`${
                !isShowSetting && pathName === '/profit' ? '#0BA5EC' : ''
              }`}
            />
            <p
              className={`${
                !isShowSetting && pathName === '/profit'
                  ? 'text-primary font-semibold'
                  : ''
              }`}
            >
              Lời lỗ
            </p>
          </div>
        </Link> */}
        <Menu as="div">
          <Menu.Button>
            <div className={itemSideBarStyle}>
              {isShowSetting ||
              pathName === '/account' ||
              pathName === '/setting' ||
              pathName === '/support' ||
              pathName === '/order-history' ? (
                <RiAccountBoxFill color="#0BA5EC" className="text-2xl" />
              ) : (
                <RiAccountBoxLine className="text-2xl" />
              )}

              <p
                className={`${
                  isShowSetting ||
                  pathName === '/account' ||
                  pathName === '/setting' ||
                  pathName === '/support' ||
                  pathName === '/order-history'
                    ? 'text-primary font-semibold'
                    : ''
                }`}
              >
                Tài khoản
              </p>
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className="absolute left-[75px] z-[90] top-[300px] w-[230px] bg-black py-4 text-text_main_dark border border-solid border-tab_background text-base font-semibold leading-[22px]">
              <ul className="flex flex-col gap-2">
                <div
                  className={`px-4 cursor-pointer ${
                    isShowSetting ? activeStyle : ''
                  }`}
                  onClick={() => {
                    setIsShowSetting(!isShowSetting);
                  }}
                >
                  <li className={`flex items-center gap-2 my-2 `}>
                    <RiSettings5Fill className="text-2xl" />
                    <span className="flex-1">Cài đặt</span>
                  </li>
                </div>
                <Link
                  to={`/account`}
                  className={`${
                    pathName === '/account' && !isShowSetting ? activeStyle : ''
                  } px-4`}
                  onClick={() => {
                    setIsShowSetting(false);
                  }}
                >
                  <li className="flex items-center gap-2 my-2">
                    <RiAccountCircleFill className="text-2xl" />
                    <span className="flex-1">Thông tin tài khoản</span>
                  </li>
                </Link>
                <Link
                  to={`/order-history`}
                  className={`${
                    pathName === '/order-history' && !isShowSetting
                      ? activeStyle
                      : ''
                  } px-4`}
                >
                  <li className="flex items-center gap-2 my-2">
                    <RiTimeFill className="text-2xl" />
                    <span className="flex-1">Lịch sử lệnh</span>
                  </li>
                </Link>
              </ul>
            </div>
          </Transition>
        </Menu>
        <Menu as="div">
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            show={isShowSetting}
          >
            <div
              className="fixed left-[82px] top-[78px] bottom-0 right-0 bg-[#000] bg-opacity-25 z-50"
              onClick={() => {
                setIsShowSetting(false);
              }}
            >
              <div
                className="absolute left-0 rounded-lg top-0 w-[330px] h-[100vh] z-10 bg-black text-text_main_dark border border-solid border-tab_background"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <div className="px-4 py-2 text-base font-semibold leading-[22px] border-b border-solid border-tab_background">
                  <p>Cài đặt</p>
                </div>
                <SettingItem
                  title="Đổi mật khẩu đăng nhập"
                  handleClickSettingItem={() => {
                    setIsShowChangePasswordModal(true);
                  }}
                >
                  <RiLockPasswordFill className="text-xl text-primary" />
                </SettingItem>
                <SettingItem
                  title="Đổi mã PIN"
                  handleClickSettingItem={() => {
                    account
                      ? setIsShowChangePinModal(true)
                      : setIsShowCompleteAccountModal(true);
                  }}
                >
                  <RiMailLockFill className="text-xl text-primary" />
                </SettingItem>
                <SettingItem
                  title="Hỗ trợ qua điện thoại"
                  handleClickSettingItem={() => {
                    setIsShowPhoneSupportModal(true);
                  }}
                >
                  <RiPhoneFill className="text-xl text-primary" />
                </SettingItem>
                <SettingItem
                  title="Hỗ trợ qua email"
                  handleClickSettingItem={() => {
                    setIsShowEmailSupportModal(true);
                  }}
                >
                  <RiMailFill className="text-xl text-primary rou" />
                </SettingItem>
              </div>
            </div>
          </Transition>
        </Menu>
      </div>
      <ChangePasswordModel
        isShowChangePasswordModal={isShowChangePasswordModal}
        setIsShowChangePasswordModal={setIsShowChangePasswordModal}
      />
      <ChangePinModel
        isShowChangePINModal={isShowChangePinModal}
        setIsShowChangePINModal={setIsShowChangePinModal}
      />
      <RequestCompleteAccountModal
        isOpen={isShowCompleteAccountModal}
        closeModal={() => {
          setIsShowCompleteAccountModal(false);
        }}
        imgClassName="w-[120px]"
      />
      <PhoneSupportModel
        isShowPhoneSupportModal={isShowPhoneSupportModal}
        setIsShowPhoneSupportModal={setIsShowPhoneSupportModal}
      />
      <EmailSupportModel
        isShowEmailSupportModal={isShowEmailSupportModal}
        setIsShowEmailSupportModal={setIsShowEmailSupportModal}
      />
    </div>
  );
};

export default SideBar;
