import { PasswordVisibilityToggle } from 'components/PasswordVisibilityToggle';
import { Button } from 'components/buttons/Button';
import { Modal } from 'components/modal/Modal';
import { AccountService } from 'core';
import { sha256 } from 'js-sha256';
import React, { useEffect, useState } from 'react';

type UpdateEmailModelProps = {
  isShowUpdateEmailModel: boolean;
  setIsShowUpdateEmailModel: (isShow: boolean) => void;
  updateSuccess?: () => void;
};

enum updateEmailSteps {
  CURRENT_PIN,
  UPDATE_EMAIL,
  CONFIRM_OTP,
  UPDATE_EMAIL_SUCCESS
}

export const UpdateEmailModel = ({
  isShowUpdateEmailModel,
  setIsShowUpdateEmailModel,
  updateSuccess
}: UpdateEmailModelProps) => {
  const [pin, setPin] = useState('');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [updateEmailStep, setUpdateEmailStep] = useState(
    updateEmailSteps.CURRENT_PIN
  );
  const [countDown, setCountDown] = useState(0);
  const [isShowPin, setIsShowPin] = useState(false);

  const handleCloseModal = () => {
    setIsShowUpdateEmailModel(false);
    setTimeout(() => {
      setError('');
      setPin('');
      setEmail('');
      setOtp('');
      setIsShowPin(false);
      setUpdateEmailStep(updateEmailSteps.CURRENT_PIN);
    }, 1000);
    updateSuccess && updateSuccess();
  };

  const sendOTP = async () => {
    const params = {
      email
    };
    const res = await AccountService.sendOTP(params);
    if (res?.data?.status === 'Success') {
      setError('');
      setUpdateEmailStep(updateEmailSteps.CONFIRM_OTP);
      setCountDown(180);
    } else {
      setError(res?.data?.error_message.split('|')[1]);
    }
  };

  const handleStepUpdateEmail = async () => {
    if (updateEmailStep === updateEmailSteps.CURRENT_PIN) {
      if (pin.length < 6) {
        setError('Mã PIN không hợp lệ');
      } else {
        const params = {
          pin: sha256(pin)
        };
        const res = await AccountService.verifyPin(params);
        if (res?.data?.status === 'Fail') {
          setError(res?.data?.error_message.split('|')[1]);
        } else {
          setError('');
          setUpdateEmailStep(updateEmailSteps.UPDATE_EMAIL);
        }
      }
    } else if (updateEmailStep === updateEmailSteps.UPDATE_EMAIL) {
      if (!email) {
        setError('Email không được để trống');
      } else if (!checkEmailFormat(email)) {
        setError('Email không hợp lệ');
      } else {
        const params = {
          email
        };

        const res = await AccountService.checkEmailExist(params);
        if (!res?.data?.data?.existed) {
          sendOTP();
        } else {
          setError('Email đã tồn tại.');
        }
      }
    } else if (updateEmailStep === updateEmailSteps.CONFIRM_OTP) {
      if (pin.length < 6) {
        setError('Mã OTP không hợp lệ');
      } else {
        const params = {
          email,
          otp: otp
        };

        const res = await AccountService.updateEmail(params);
        if (res?.data?.status === 'Success') {
          setError('');
          setUpdateEmailStep(updateEmailSteps.UPDATE_EMAIL_SUCCESS);
        } else {
          setError(res?.data?.error_message.split('|')[1]);
        }
      }
    }
  };

  const checkEmailFormat = (email: string) => {
    // format corect is email@gmail.com not email@gmail.cob
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com)$/;
    return re.test(email);
  };

  const handleCountDown = () => {
    setCountDown(countDown - 1);
  };

  const formatCountDown = (countDown: number) => {
    const minutes = Math.floor(countDown / 60);
    const seconds = countDown - minutes * 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (countDown > 0) {
      timeoutId = setTimeout(() => {
        handleCountDown();
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [countDown]);

  return (
    <div>
      <Modal
        isOpen={isShowUpdateEmailModel}
        title={`${
          updateEmailStep === updateEmailSteps.CURRENT_PIN
            ? 'Nhập mã PIN hiện tại'
            : updateEmailStep === updateEmailSteps.UPDATE_EMAIL
            ? 'Cập nhật email'
            : updateEmailStep === updateEmailSteps.CONFIRM_OTP
            ? 'Nhập mã xác nhận'
            : 'Cập nhật email thành công'
        }`}
        classNameTitle={`${
          updateEmailStep === updateEmailSteps.UPDATE_EMAIL_SUCCESS
            ? '!text-xl !font-semibold !leading-[26px]'
            : ''
        }`}
        subtitle={`
        ${
          updateEmailStep === updateEmailSteps.CURRENT_PIN
            ? 'Lộ mã PIN có thể ảnh hưởng tới bảo mật tài khoản của bạn.'
            : updateEmailStep === updateEmailSteps.UPDATE_EMAIL
            ? 'Vui lòng nhập email mới'
            : updateEmailStep === updateEmailSteps.CONFIRM_OTP
            ? `Vui lòng nhập mã OTP vừa gửi đến ${email}`
            : 'Thông tin email của bạn vừa cập nhật thành công'
        }
        `}
        subtitleClassName={`!text-[14px] !font-normal leading-5 ${
          updateEmailStep === updateEmailSteps.UPDATE_EMAIL_SUCCESS &&
          'text-text_main_light !text-base !leading-[22px]'
        }`}
        closeModal={() => {}}
        className={`max-w-[640px]`}
        typePopup={`${
          updateEmailStep === updateEmailSteps.UPDATE_EMAIL_SUCCESS
            ? 'Success'
            : 'changePassword'
        }`}
      >
        {updateEmailStep !== updateEmailSteps.UPDATE_EMAIL_SUCCESS && (
          <div className="pt-3">
            {updateEmailStep === updateEmailSteps.CURRENT_PIN && (
              <div className="form-input relative">
                <label htmlFor="" className="text-sm font-normal leading-5">
                  Mã PIN hiện tại
                  <span className="text-carminePink"> *</span>
                </label>
                <input
                  type={isShowPin ? 'text' : 'password'}
                  placeholder="Nhập mã PIN hiện tại"
                  className={`form-input__input ${
                    error && 'form-input__input_error'
                  }`}
                  onChange={e => {
                    if (e.target.value.length <= 6) {
                      setError('');
                      setPin(e.target.value);
                    }
                  }}
                  value={pin}
                ></input>
                <PasswordVisibilityToggle
                  toggleVisibility={() => {
                    setIsShowPin(!isShowPin);
                  }}
                  visible={isShowPin}
                />
                {error && (
                  <div className="mt-[2px]">
                    <p className="text-sm font-normal leading-5 text-carminePink">
                      {error}
                    </p>
                  </div>
                )}
              </div>
            )}
            {updateEmailStep === updateEmailSteps.UPDATE_EMAIL && (
              <div className="form-input">
                <label htmlFor="" className="text-sm font-normal leading-5">
                  Email
                  <span className="text-carminePink"> *</span>
                </label>
                <input
                  type="text"
                  placeholder="Nhập email"
                  className={`form-input__input ${
                    error && 'form-input__input_error'
                  }`}
                  onChange={e => {
                    setError('');
                    setEmail(e.target.value);
                  }}
                  value={email}
                ></input>
                {error && (
                  <div className="mt-[2px]">
                    <p className="text-sm font-normal leading-5 text-carminePink">
                      {error}
                    </p>
                  </div>
                )}
              </div>
            )}
            {updateEmailStep === updateEmailSteps.CONFIRM_OTP && (
              <div className="form-input">
                <label htmlFor="" className="text-sm font-normal leading-5">
                  Mã OTP
                  <span className="text-carminePink"> *</span>
                </label>
                <input
                  type="number"
                  placeholder="Nhập mã OTP"
                  className={`form-input__input ${
                    error && 'form-input__input_error'
                  }`}
                  onChange={e => {
                    setError('');
                    if (e.target.value.length <= 6) {
                      setOtp(e.target.value);
                    }
                  }}
                  value={otp}
                ></input>
                {error && (
                  <div className="mt-[2px]">
                    <p className="text-sm font-normal leading-5 text-carminePink">
                      {error}
                    </p>
                  </div>
                )}
                <div className="flex justify-between text-sm font-normal leading-5">
                  <p>
                    Mã OTP có hiệu lực trong{' '}
                    <span className="font-semibold">
                      {formatCountDown(countDown)}
                    </span>
                  </p>
                  <p>
                    Bạn chưa nhận được mã?{' '}
                    <span
                      className="font-semibold text-primary cursor-pointer"
                      onClick={sendOTP}
                    >
                      Gửi lại mã
                    </span>
                  </p>
                </div>
              </div>
            )}
          </div>
        )}

        <div
          className={`flex items-center gap-3 ${error ? 'mt-9' : 'mt-[64px]'}`}
        >
          {updateEmailStep !== updateEmailSteps.UPDATE_EMAIL_SUCCESS && (
            <Button
              className="flex-1"
              variant="secondary"
              onClick={handleCloseModal}
            >
              Đóng
            </Button>
          )}

          {updateEmailStep === updateEmailSteps.UPDATE_EMAIL_SUCCESS ? (
            <Button
              variant="primary"
              className="flex-1"
              onClick={handleCloseModal}
            >
              Xác nhận
            </Button>
          ) : (
            <Button
              variant="primary"
              className="flex-1"
              onClick={handleStepUpdateEmail}
            >
              Tiếp theo
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
};
