import React, { useEffect, useState } from 'react';
import { StockSymbol } from 'components/stockSymbol/StockSymbol';
import StockList from './components/StockList';
import { AccountService } from 'core';
import { useAccount } from 'core/contexts/AccountContext';
import { TitleSection } from 'components';
export enum MARKET_TAB {
  OVERVIEW,
  MOVEMENTS
}

const MarketPage = () => {
  const { updateWatchlist } = useAccount();

  const getFavoriteSymbol = async () => {
    const response = await AccountService.getwatchlist();
    if (response?.data) {
      const data = response?.data?.data;
      updateWatchlist(data);
    }
  };

  useEffect(() => {
    getFavoriteSymbol();
  }, []);

  return (
    <div className="min-h-full bg-blackBlue p-2">
      <div className="rounded-lg border-style !border-t-0 bg-black">
        <TitleSection title="Các chỉ số" />
        <div className="p-4 max-[1280px]:overflow-x-scroll scrollbar-horizontal">
          <StockSymbol />
        </div>
      </div>
      <div className="mt-2">
        <StockList />
      </div>
    </div>
  );
};

export default MarketPage;
