import { Button } from 'components/buttons/Button';
import { Modal } from './Modal';
import React from 'react';

type DEProps = {
  title: string;
  content?: string;
  isOpen: boolean;
  closeModal: () => void;
  className?: string;
};

const DEModal = ({ title, content, isOpen, closeModal }: DEProps) => {
  return (
    <Modal
      isOpen={isOpen}
      title={title}
      closeModal={closeModal}
      className={`max-w-[600px]`}
    >
      <div>
        <p className='text-sm leading-5'>{content}</p>
        <div className="flex items-center gap-3 mt-10">
          <Button
            className="flex-1 text-primary font-semibold !bg-black"
            onClick={() => closeModal()}
          >
            Đóng
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DEModal;
