import React, { useEffect, useState } from 'react';
import { Modal } from './Modal';
import { Button } from '../buttons/Button';
import { formatCurrency } from 'core/utils';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import CurrencyFormat from 'react-currency-format';
import { AssetStockItem } from 'core/types';
import { SellStockConfirmModal } from './SellStockConfirmModal';
import { toast } from 'react-toastify';
import { useAccount, useLoading } from 'core/contexts';
import { MarketStockService } from 'core';
import { checkSavePin } from 'core/utils';
import { RiCheckboxCircleFill, RiErrorWarningLine } from 'react-icons/ri';
import { formatStockPrice } from 'core/utils';

type ModalProps = {
  isOpen: boolean;
  resetData: () => void;
  closeModal: () => void;
  stocks: AssetStockItem[];
};

export const SellStocksModal = ({
  isOpen,
  stocks,
  closeModal,
  resetData
}: ModalProps) => {
  const { isLoading, showLoading, hideLoading } = useLoading();
  const { account, userInfo } = useAccount();
  const [isShowConfirmOrder, setIsShowConfirmOrder] = useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors, isValid }
  } = useForm<{
    stocks: {
      name: string;
      floor: number;
      ceiling: number;
      price: number;
      available: number;
      quantity: number;
    }[];
  }>({
    mode: 'onChange'
  });
  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'stocks' // unique name for your Field Array
  });

  if (isOpen && errors?.stocks?.[0]) {
    toast(
      errors?.stocks[0]?.quantity?.message || errors?.stocks[0]?.price?.message,
      {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />
      }
    );
  }

  const innit = async () => {
    const initData: any[] = [];
    for (let i = 0; i < stocks.length; i++) {
      if (stocks[i].stock_symbol) {
        const res = await MarketStockService.getStockInfo(
          stocks[i].stock_symbol
        );
        const data = res?.data?.data;
        const q =
          stocks[i].available_quantity <= 100
            ? stocks[i].available_quantity
            : Math.floor(stocks[i].available_quantity / 100) * 100;
        initData.push({
          name: data?.stock_code,
          floor: data?.floor / 1000,
          ceiling: data?.ceiling / 1000,
          lot_size: data?.lot_size,
          price: data?.floor / 1000,
          available: stocks[i].available_quantity,
          quantity: q
        });
      }
    }
    setValue('stocks', initData);
  };

  const onError = () => {};

  useEffect(() => {
    if (isOpen) {
      innit();
    } else {
      reset();
    }
  }, [isOpen]);

  const onSubmit = async (data: any) => {
    setIsShowConfirmOrder(true);
  };

  const submitOrder = async (
    pin: string,
    type?: 'PIN' | 'SMART_OTP' | 'SMS_OTP',
    isCheckSavePinCode?: boolean
  ) => {
    if (pin) {
      showLoading();
      const values = getValues()?.stocks;
      let isFailed = false;
      for (let i = 0; i < values.length; i++) {
        let paramsdata: any = {
          account_no: account?.account_id,
          matching_type: 'LO',
          stock_symbol: values[i]?.name,
          price: (values[i]?.price * 1000).toFixed(2),
          quantity: values[i]?.quantity,
          note: '',
          pin: pin,
          factor_authen_enum_type: type
        };
        if (type === 'SMART_OTP' || type === 'SMS_OTP') {
          paramsdata.otp = pin;
          paramsdata.pin = '';
        }
        const res = await MarketStockService.sellStock(
          userInfo?.user_id || '',
          paramsdata
        );
        const data = res?.data;
        if (data?.status === 'Fail') {
          isFailed = true;
        }
        if (
          data?.error_code === 'PinNotMatch' ||
          data?.error_code === 'PinVerifyLocked'
        ) {
          toast(data?.error_message.split('|')[1], {
            type: 'error',
            icon: <RiErrorWarningLine className="text-[#F04438]" />
          });
          hideLoading();
          return;
        }
      }

      if (isFailed) {
        toast(
          'Có ít nhất một lệnh trong những lệnh bạn đặt gặp lỗi. Vui lòng kiểm tra sổ lệnh để tham khảo lệnh đặt thành công và thử lại.',
          {
            type: 'error',
            icon: <RiErrorWarningLine className="text-[#F04438]" />
          }
        );
      } else {
        toast(
          'Yêu cầu đặt lệnh đã được tiếp nhận. Quý khách vui lòng kiểm tra trạng thái lệnh trong sổ lệnh.',
          {
            type: 'success',
            icon: <RiCheckboxCircleFill className="text-[#00C802]" />
          }
        );
      }
      if (isCheckSavePinCode && type === 'PIN') {
        await checkSavePin(pin);
      }
      hideLoading();
      resetData();
      closeModal();
      setIsShowConfirmOrder(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        hasCloseBtn
        closeBtnClassName="text-[#667085]"
        title="Xác nhận giao dịch bán"
        closeModal={closeModal}
        className={`max-w-[640px]`}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
          >
            <path
              d="M3.25033 3.25H22.7503C23.0376 3.25 23.3132 3.36414 23.5164 3.5673C23.7195 3.77047 23.8337 4.04602 23.8337 4.33333V21.6667C23.8337 21.954 23.7195 22.2295 23.5164 22.4327C23.3132 22.6359 23.0376 22.75 22.7503 22.75H3.25033C2.96301 22.75 2.68746 22.6359 2.48429 22.4327C2.28113 22.2295 2.16699 21.954 2.16699 21.6667V4.33333C2.16699 4.04602 2.28113 3.77047 2.48429 3.5673C2.68746 3.36414 2.96301 3.25 3.25033 3.25V3.25ZM4.33366 5.41667V20.5833H21.667V5.41667H4.33366ZM9.20866 15.1667H15.167C15.3107 15.1667 15.4484 15.1096 15.55 15.008C15.6516 14.9064 15.7087 14.7687 15.7087 14.625C15.7087 14.4813 15.6516 14.3436 15.55 14.242C15.4484 14.1404 15.3107 14.0833 15.167 14.0833H10.8337C10.1154 14.0833 9.42649 13.798 8.91858 13.2901C8.41067 12.7822 8.12533 12.0933 8.12533 11.375C8.12533 10.6567 8.41067 9.96783 8.91858 9.45992C9.42649 8.95201 10.1154 8.66667 10.8337 8.66667H11.917V6.5H14.0837V8.66667H16.792V10.8333H10.8337C10.69 10.8333 10.5522 10.8904 10.4506 10.992C10.3491 11.0936 10.292 11.2313 10.292 11.375C10.292 11.5187 10.3491 11.6564 10.4506 11.758C10.5522 11.8596 10.69 11.9167 10.8337 11.9167H15.167C15.8853 11.9167 16.5742 12.202 17.0821 12.7099C17.59 13.2178 17.8753 13.9067 17.8753 14.625C17.8753 15.3433 17.59 16.0322 17.0821 16.5401C16.5742 17.048 15.8853 17.3333 15.167 17.3333H14.0837V19.5H11.917V17.3333H9.20866V15.1667Z"
              fill="#98A2B3"
            />
          </svg>
        }
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <p className="text-sm">
              Quý khách có xác nhận đặt lệnh bán các mã sau?
            </p>
            <div className="mt-6">
              <div className="flex gap-4 lg:gap-12 items-center py-3 text-sm text-text_main_light">
                <div className="flex-1">Mã</div>
                <div className="w-[100px] text-right">Khối lượng</div>
                <div className="w-[100px] text-right">Giá bán</div>
              </div>
              {fields.map((field: any, index) => {
                return (
                  <div
                    key={field.id}
                    className="flex gap-4 lg:gap-12 items-center border-t border-[#1D2939] py-3"
                  >
                    <div className="flex-1">
                      <div>{field?.name}</div>
                      <div className="flex items-center gap-[2px]">
                        <span className="text-sm text-ceilingPriceColor">
                          {formatStockPrice(field.ceiling * 1000)}
                        </span>
                        <span className="text-[10px] text-text_main_light">
                          |
                        </span>
                        <span className="text-sm text-floorPriceColor">
                          {formatStockPrice(field.floor * 1000)}
                        </span>
                      </div>
                    </div>
                    <Controller
                      control={control}
                      name={`stocks.${index}.quantity`}
                      rules={{
                        required: 'Vui lòng nhập khối lượng',
                        min: {
                          value: 0,
                          message: 'Khối lượng phải lớn hơn 0'
                        },
                        max: {
                          value: field?.available,
                          message: `Khối lượng phải nhỏ hơn hoặc bằng ${formatCurrency(
                            field?.available
                          )}`
                        },
                        validate: value => {
                          if (value > 100 && value % field?.lot_size !== 0) {
                            return 'Khối lượng đặt của lệnh lô lẻ từ 1 đến 99';
                          }
                        }
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <CurrencyFormat
                          className={`bg-[#0C111D] w-[100px] px-2 py-3 text-sm text-right rounded-[6px] border border-[#1D2939] focus:outline-none ${
                            errors.stocks?.[index]?.quantity &&
                            'form-input__input_error text-error'
                          }`}
                          value={value}
                          onValueChange={(v: any) => {
                            const floatValue = v?.floatValue || '';
                            onChange(floatValue);
                          }}
                          thousandSeparator={true}
                          decimalScale={2}
                          allowNegative={false}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={`stocks.${index}.price`}
                      rules={{
                        required: 'Vui lòng nhập giá',
                        min: {
                          value: field?.floor,
                          message: `Giá đặt phải nhỏ hơn hoặc bằng ${field?.floor}`
                        },
                        max: {
                          value: field?.ceiling,
                          message: `Giá đặt phải nhỏ hơn hoặc bằng ${field?.ceiling}`
                        }
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <CurrencyFormat
                          className={`bg-[#0C111D] w-[100px] px-2 py-3 text-sm text-right rounded-[6px] border border-[#1D2939] focus:outline-none ${
                            errors.stocks?.[index]?.price &&
                            'form-input__input_error text-error'
                          }`}
                          value={value}
                          onValueChange={(v: any) => {
                            const floatValue = v?.floatValue || '';
                            onChange(floatValue);
                            onError();
                          }}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale
                          allowNegative={false}
                        />
                      )}
                    />
                  </div>
                );
              })}
            </div>
            <div className="flex items-center gap-3 mt-10">
              <Button
                className="flex-1"
                type="button"
                variant="secondary"
                onClick={() => closeModal()}
              >
                Huỷ
              </Button>
              <Button
                disabled={!isValid || isLoading}
                variant="primary"
                className="flex-1"
                type="submit"
              >
                Xác nhận
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <SellStockConfirmModal
        isOpen={isShowConfirmOrder}
        title="Xác nhận đặt lệnh"
        stocks={getValues()?.stocks}
        closeModal={() => setIsShowConfirmOrder(false)}
        onSubmit={(pin, type) => submitOrder(pin, type, true)}
      />
    </>
  );
};
