import React, { useEffect, useState } from 'react';
import SortTable from './SortTable';
import StockRowMarket from './StockRowMarket';
import { useSortableData } from 'core/hooks';
import { SpinnerIcon } from './spinner/SpinnerIcon';

const _ = require('lodash');

const StockTableMarket = ({
  data,
  handleClickStock
}: {
  data: any[];
  showFull?: boolean;
  handleClickStock?: (stock: string) => void;
}) => {
  const [dataSorted, setDataSorted] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { requestSort, sortConfig, setSortConfig } = useSortableData();

  useEffect(() => {
    setSortConfig(undefined);
    setDataSorted(data);
  }, [data]);

  useEffect(() => {
    onSort();
  }, [sortConfig]);

  const onSort = async () => {
    if (!data) {
      return;
    }
    let dataNew = [...data];
    if (!sortConfig) {
      setDataSorted(dataNew);
      setLoading(false);
      return;
    }
    const sorted_data = _.orderBy(
      dataNew,
      [
        (o: any) => {
          return o[sortConfig.key] || '';
        }
      ],
      [sortConfig.direction]
    );
    setDataSorted(sorted_data);
    setLoading(false);
  };

  return (
    <div className="text-sm font-medium h-full relative pr-[2px] pt-[42px]">
      <div
        className={`absolute left-0 top-0 w-full flex bg-black_blue px-3 py-2 gap-2 xl:px-8`}
      >
        <div className={`w-[8%]`}>
          <div
            className={`inline-flex items-center cursor-pointer`}
            onClick={() => requestSort('symbol')}
          >
            <span>Mã CP</span>
            <SortTable
              sortDirection={
                sortConfig?.key === 'symbol' ? sortConfig?.direction : undefined
              }
            />
          </div>
        </div>
        <div className={`text-right w-[6%]`}>
          <div
            className={`inline-flex items-center justify-end cursor-pointer`}
            onClick={() => requestSort('price')}
          >
            <span>Giá</span>
            <SortTable
              sortDirection={
                sortConfig?.key === 'price' ? sortConfig?.direction : undefined
              }
            />
          </div>
        </div>
        <div className={`text-right w-[13%]`}>
          <div
            className={`inline-flex items-center justify-end cursor-pointer`}
            onClick={() => requestSort('change_ratio')}
          >
            <span>Biến động</span>
            <SortTable
              sortDirection={
                sortConfig?.key === 'change_ratio'
                  ? sortConfig?.direction
                  : undefined
              }
            />
          </div>
        </div>
        <div className={`text-right w-[10%]`}>
          <div
            className={`inline-flex items-center justify-end cursor-pointer`}
            onClick={() => requestSort('volume')}
          >
            <span>KLGD</span>
            <SortTable
              sortDirection={
                sortConfig?.key === 'volume' ? sortConfig?.direction : undefined
              }
            />
          </div>
        </div>
        <div className={`text-right w-[10%]`}>
          <div
            className={`inline-flex items-center justify-end cursor-pointer`}
            onClick={() => requestSort('value')}
          >
            <span>GTGD (đ)</span>
            <SortTable
              sortDirection={
                sortConfig?.key === 'value' ? sortConfig?.direction : undefined
              }
            />
          </div>
        </div>
        <div className={`text-right w-[10%]`}>
          <div
            className={`inline-flex items-center justify-end cursor-pointer`}
            onClick={() => requestSort('peak_in_year')}
          >
            <span>Đỉnh 52T</span>
            <SortTable
              sortDirection={
                sortConfig?.key === 'peak_in_year'
                  ? sortConfig?.direction
                  : undefined
              }
            />
          </div>
        </div>
        <div className={`text-right w-[10%]`}>
          <div
            className={`inline-flex items-center justify-end cursor-pointer`}
            onClick={() => requestSort('peak_in_three_months')}
          >
            <span>Đỉnh 3Th</span>
            <SortTable
              sortDirection={
                sortConfig?.key === 'peak_in_three_months'
                  ? sortConfig?.direction
                  : undefined
              }
            />
          </div>
        </div>
        <div className={`text-right w-[10%]`}>
          <div
            className={`inline-flex items-center justify-end cursor-pointer`}
            onClick={() => requestSort('bottom_in_year')}
          >
            <span>Đáy 52T</span>
            <SortTable
              sortDirection={
                sortConfig?.key === 'bottom_in_year'
                  ? sortConfig?.direction
                  : undefined
              }
            />
          </div>
        </div>
        <div className={`text-right w-[10%]`}>
          <div
            className={`inline-flex items-center justify-end cursor-pointer`}
            onClick={() => requestSort('bottom_in_three_months')}
          >
            <span>Đáy 3Th</span>
            <SortTable
              sortDirection={
                sortConfig?.key === 'bottom_in_three_months'
                  ? sortConfig?.direction
                  : undefined
              }
            />
          </div>
        </div>
        <div className="flex-1 text-right">Hành động</div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center">
          <SpinnerIcon className="scale-50" />
        </div>
      ) : (
        <div
          className={`max-h-full overflow-y-auto px-2 scrollbar-custom xl:px-8`}
        >
          {dataSorted?.map(stock => {
            return (
              <StockRowMarket
                key={stock?.symbol}
                stock={stock}
                handleClickStock={handleClickStock}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default StockTableMarket;
