import React, { useEffect, useState } from 'react';
import { Modal } from './Modal';
import { Button } from '../buttons/Button';
import { ForgotPinButton } from '../ForgotPinButton';
import { SmartOtpGuideButton } from '../SmartOtpGuideButton';
import { RiInformationLine } from 'react-icons/ri';
import { sha256 } from 'js-sha256';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { decryptData } from 'core/utils';
import { AccountService } from 'core';
import { useAccount } from 'core/contexts/AccountContext';
import { toast } from 'react-toastify';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Countdown } from '../countdown';

type ModalProps = {
  title: string;
  subTitle?: string;
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: (pin: string, type?: 'PIN' | 'SMART_OTP' | 'SMS_OTP') => void;
  stocks: any[];
  className?: string;
};

export const SellStockConfirmModal = ({
  title,
  subTitle,
  isOpen,
  onSubmit,
  closeModal,
  stocks
}: ModalProps) => {
  const { userInfo } = useAccount();
  const [pinCode, setPinCode] = useState<string>();
  const [showPin, setShowPin] = useState(false);
  const [pin, setPin] = useState<string>();
  const [time, setTime] = useState(0);
  const [timeResendOtp, setTimeResendOtp] = useState(0);
  const [factorAuthenType, setFactorAuthenType] = useState<
    'PIN' | 'SMART_OTP' | 'SMS_OTP'
  >();
  const pinCache = localStorage.getItem('PC') || undefined;

  const getPinCache = async () => {
    const pinData = (await decryptData(pinCache)) || '';
    setPin(pinData);
  };

  useEffect(() => {
    if (!isOpen) {
      getPinCache();
      setPinCode(undefined);
      setShowPin(false);
    }
  }, [isOpen]);

  const getSettings = async () => {
    const res = await AccountService.getSettings('TwoFactorAuthentication');
    const type = res?.data.data?.value;
    setFactorAuthenType(type);
  };

  const getSmsOtp = async (userId: string) => {
    const res = await AccountService.getSmsOtp(userId);
    const data = res?.data;
    if (data?.status === 'Fail') {
      toast(data?.error_message.split('|')[1] || data?.error_message, {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />
      });
    }
    if (data?.status === 'Success') {
      setTime(180);
    }
  };

  const reSendOtp = () => {
    if (userInfo && timeResendOtp === 0) {
      setTimeResendOtp(120);
      getSmsOtp(userInfo?.user_id);
    }
  };

  useEffect(() => {
    if (isOpen && factorAuthenType === 'SMS_OTP' && userInfo) {
      getSmsOtp(userInfo?.user_id);
    }
  }, [isOpen, factorAuthenType]);

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      closeModal={closeModal}
      className={`max-w-[500px]`}
    >
      <div>
        <p className="text-sm mt-[-4px] mb-6">{subTitle}</p>
        <div className="text-[#98A2B3] text-sm rounded-md border border-[#D0D5DD] px-4 py-3">
          <div className="flex">
            <div className="flex-1">Mã</div>
            <p className="w-[150px] text-right">Giá đặt</p>
            <p className="w-[150px] text-right">KL đặt</p>
          </div>
          {stocks?.map((i, idx) => (
            <div key={idx} className="flex">
              <div className="flex-1">
                <span className="font-semibold">{i.name}</span>
              </div>
              <p className="w-[150px] text-right">
                <span className="font-semibold">
                  {typeof i.price === 'number'
                    ? i?.price?.toLocaleString('en-US')
                    : i?.price}
                </span>
              </p>
              <p className="w-[150px] text-right">
                <span className="font-semibold">
                  {i?.quantity?.toLocaleString('en-US')}
                </span>
              </p>
            </div>
          ))}
        </div>
        {factorAuthenType === 'PIN' ? (
          <div className="flex items-center gap-2 mt-4">
            <div className="checkbox">
              <input
                id="show-pin-checkbox"
                type="checkbox"
                className={'w-[20px] h-[20px] checkbox__input'}
                onChange={e => {
                  if (e.target.checked) {
                    localStorage.setItem('IS_HIDE_PIN_POPUP', 'TRUE');
                  } else {
                    localStorage.removeItem('IS_HIDE_PIN_POPUP');
                  }
                }}
              />
              <label
                htmlFor="show-pin-checkbox"
                className={'text-xs checkbox__label'}
              >
                Không hiển thị xác nhận cho các lệnh tiếp theo
              </label>
            </div>

            <RiInformationLine className="text-[#667085] text-[18px]" />
          </div>
        ) : null}
        <div className="flex items-center justify-between gap-2 mt-8 mb-4">
          <div className="text-sm">
            {factorAuthenType === 'SMART_OTP'
              ? 'Mã Smart Otp giao dịch'
              : factorAuthenType === 'SMS_OTP'
              ? 'Mã SMS OTP'
              : 'Mã pin giao dịch'}
          </div>
          {factorAuthenType === 'PIN' ? (
            <div className="checkbox">
              <input
                id="save-pin-checkbox"
                type="checkbox"
                defaultChecked={!!pin}
                disabled={!!pin}
                className={'w-[20px] h-[20px] checkbox__input'}
                onChange={e => {
                  if (e.target.checked) {
                    localStorage.setItem('IS_SAVE_P', 'TRUE');
                  } else {
                    localStorage.removeItem('IS_SAVE_P');
                  }
                }}
              />
              <label
                htmlFor="save-pin-checkbox"
                className={'text-sm checkbox__label'}
              >
                Lưu mã PIN
              </label>
            </div>
          ) : factorAuthenType === 'SMS_OTP' ? (
            <div className="text-sm">
              Mã có hiệu lực trong{' '}
              <span className="font-semibold inline-block min-w-[45px]">
                <Countdown endTime={time} />
              </span>
            </div>
          ) : null}
        </div>
        <div className="relative">
          <input
            value={pinCode}
            type={showPin ? 'text' : 'password'}
            disabled={!!pin}
            className={`w-full !bg-[#0C111D] border border-[#D0D5DD] rounded-md text-sm pl-3 pr-10 py-2 placeholder:text-[#98A2B3]`}
            placeholder={
              factorAuthenType === 'SMART_OTP'
                ? 'Nhập mã Smart Otp'
                : factorAuthenType === 'SMS_OTP'
                ? 'Nhập mã SMS OTP'
                : 'Nhập mã pin'
            }
            defaultValue={pin ? '******' : ''}
            maxLength={6}
            onChange={e => {
              const v = e.target.value;
              setPinCode(v);
            }}
          />
          <button
            onClick={() => setShowPin(!showPin)}
            disabled={!!pin}
            type="button"
            className="absolute right-4 top-1/2 transform -translate-y-1/2 disabled:opacity-50"
          >
            {showPin ? <RiEyeLine /> : <RiEyeOffLine />}
          </button>
        </div>
        {factorAuthenType === 'PIN' ? (
          <div className="text-right">
            <ForgotPinButton />
          </div>
        ) : factorAuthenType === 'SMART_OTP' ? (
          <div className="text-right">
            <SmartOtpGuideButton />
          </div>
        ) : factorAuthenType === 'SMS_OTP' ? (
          <div className="text-xs mt-4">
            <div className="mb-1">
              Vui lòng nhập mã OTP vừa gửi tới {userInfo?.phone_number}
            </div>
            <div>
              Bạn chưa nhận được mã?
              <button
                className="font-semibold text-primary ml-2 disabled:opacity-50"
                onClick={() => reSendOtp()}
                disabled={timeResendOtp > 0}
              >
                Gửi lại mã OTP
              </button>
              <span className="hidden">
                <Countdown
                  endTime={timeResendOtp}
                  onTimeEnd={() => setTimeResendOtp(0)}
                />
              </span>
            </div>
          </div>
        ) : null}
        <div className="flex items-center gap-3 mt-10">
          <Button
            className="flex-1"
            variant="secondary"
            onClick={() => closeModal()}
          >
            Đóng
          </Button>
          <Button
            variant="primary"
            className="flex-1"
            onClick={() => {
              if (
                (factorAuthenType === 'SMART_OTP' ||
                  factorAuthenType === 'SMS_OTP') &&
                pinCode
              ) {
                onSubmit(pinCode, factorAuthenType);
                return;
              }
              if (pin) {
                onSubmit(pin, factorAuthenType);
                return;
              }
              if (pinCode) {
                onSubmit(sha256(pinCode), factorAuthenType);
              }
            }}
          >
            Xác nhận
          </Button>
        </div>
      </div>
    </Modal>
  );
};
