import React, { useState, useEffect } from 'react';
import { OnboardingService } from 'core/services';

import dayjs from 'dayjs';

export const ContractNormal = () => {
  const [contractUrl, setContractUrl] = useState<any>();

  const getData = async () => {
    const configRes = await OnboardingService.fetchSystemConfig();
    const configData = configRes?.data || [];
    const res = await OnboardingService.fetchUser();
    const userData = res?.data;
    if (configData && userData) {
      const configUrl = configData.find(
        (i: any) => i?.key === 'SBBS_CONTRACT_OPEN_NORMAL'
      );
      if (configUrl) {
        const newUrl =
          configUrl?.value +
          `?full_name=${userData?.full_name}&card_number=${
            userData?.cccd
          }&card_provider=${userData?.issued_date}&phone=${
            userData?.user_name
          }${
            userData?.temporary_residence_address
              ? `&temporary_residence_address=${userData?.temporary_residence_address}`
              : ''
          }${userData?.provided ? `&issue_place=${userData?.provided}` : ''}${
            userData?.email ? `&email=${userData?.email}` : ''
          }${userData?.gender ? `&gender=${userData?.gender}` : ''}${
            userData?.tax_number ? `&tax_number=${userData?.tax_number}` : ''
          }${
            userData?.nationality ? `&nationality=${userData?.nationality}` : ''
          }${
            userData?.permanent_address
              ? `&address_permanent=${userData?.permanent_address}`
              : ''
          }${userData?.dob ? `&dob=${userData?.dob}` : ''}`;
        setContractUrl(newUrl);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="pb-5">
      <div className="h-[432px] overflow-hidden border border-[#F0F0F0] rounded-[10px]">
        {!!contractUrl && (
          <iframe src={contractUrl} className="w-full h-full" />
        )}
      </div>
    </div>
  );
};
