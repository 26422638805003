import React, { useEffect, useState } from 'react';
import { PenIcon } from 'assets/icons/index';
import MultiCircleSupportBox from 'components/MultiCircleSupportBox';
import {
  VerifyAccountModal,
  UpdateEmailModel,
  AccountNotification,
  TitleSection
} from 'components';
import dayjs from 'dayjs';
import {
  RiCameraFill,
  RiCheckboxCircleFill,
  RiMailFill,
  RiPhoneFill
} from 'react-icons/ri';
import { AccountService } from 'core';
import { toast } from 'react-toastify';
import { useAccount } from 'core/contexts';
import { EKYC } from 'core/constant';

const randomColor = () => {
  const colorArr = [
    '#FF7337',
    '#F4D942',
    '#0BA5EC',
    '#FF7337',
    '#F4D942',
    '#0BA5EC'
  ];
  const random = Math.floor(Math.random() * colorArr.length);
  return colorArr[random];
};

const AccountPage = () => {
  const [isShowUpdateEmailModal, setIsShowUpdateEmailModal] = useState(false);
  const [isOpenVerifyAccountModal, setIsOpenVerifyAccountModal] =
    useState(false);
  const { account, userInfo, accountInfo, updateUserInfo } = useAccount();

  useEffect(() => {
    if (!account) {
      if (userInfo?.ekyc_status === EKYC.SUCCESS) {
        setIsOpenVerifyAccountModal(true);
      } else {
        if (userInfo?.ekyc_status === EKYC.MANUAL) {
          if (accountInfo?.pin || accountInfo?.sign_contract) {
            setIsOpenVerifyAccountModal(true);
          } else {
            setIsOpenVerifyAccountModal(false);
          }
        }
      }
    }
  }, []);

  const getUserData = async () => {
    const res = await AccountService.getUserInfo();
    if (res?.data?.status === 'Success') {
      updateUserInfo(res?.data?.data);
    }
  };

  const getFistLetterName = (name: string) => {
    if (name) {
      const words = name.split(' ');
      const lastWord = words[words.length - 1];
      return lastWord?.charAt(0)?.toUpperCase();
    }
    return '';
  };

  const handleChangeAvatar = async (e: any) => {
    const file = e.target.files[0];

    // upload image
    const params = {
      FileType: 'Avatar'
    };
    const res = await AccountService.getUrlUploadAvatar(params);
    if (res?.data?.status === 'Success') {
      const url = res?.data?.data?.url;
      const file_path = res?.data?.data?.file_path;
      if (url) {
        const res = await fetch(url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type
          }
        });
        if (res.status === 200) {
          const params = {
            avatar: file_path
          };
          const res = await AccountService.updateAvatar(params);
          if (res?.data?.status === 'Success') {
            toast('Cập nhật ảnh đại diện thành công!', {
              type: 'success',
              icon: <RiCheckboxCircleFill className="text-[#00C802]" />
            });
            getUserData();
          } else {
            toast('Cập nhật ảnh đại diện thất bại!', {
              type: 'error',
              icon: <RiCheckboxCircleFill className="text-[#F04438]" />
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div className="flex p-2 gap-2">
        <div className="w-[62%] border-style !border-t-0 bg-black rounded-lg">
          <TitleSection title="Thông tin tài khoản" />
          {account ? (
            <div className="pb-6 px-4 pt-4 flex flex-col gap-4">
              <div className="flex gap-4 mb-8">
                <div className="relative w-[68px] cursor-pointer">
                  {userInfo?.avatar ? (
                    <img
                      src={userInfo.avatar}
                      alt="Avatar"
                      className="w-[56px] h-[56px] rounded-full"
                    />
                  ) : (
                    <div
                      className={`w-[56px] h-[56px] !rounded-full flex justify-center items-center cursor-pointer`}
                      style={{ backgroundColor: randomColor() }}
                    >
                      <p className="text-[22.5px] font-bold tracking-[-0.138px] text-[#000]">
                        {getFistLetterName(userInfo?.full_name || '')}
                      </p>
                    </div>
                  )}
                  <div className="absolute bottom-0 left-[40px] w-7 h-7 flex justify-center items-center bg-text_main_dark rounded-full">
                    <RiCameraFill className="text-black" />
                  </div>
                  <input
                    type="file"
                    className="absolute top-0 bottom-0 w-full opacity-0"
                    onChange={handleChangeAvatar}
                    accept="image/png, image/jpeg"
                  />
                </div>
                <div className="flex items-center">
                  <p className="text-base font-semibold leading-[22px]">
                    {userInfo?.full_name}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-sm font-semibold leading-5">
                  Thông tin cơ bản
                </p>
              </div>
              <div className="flex gap-2">
                <div className="w-1/2 flex flex-col gap-2">
                  <div className="form-input">
                    <label
                      htmlFor=""
                      className="form-input__label_account-info"
                    >
                      Họ và tên
                    </label>
                    <input
                      className="form-input__input form-input__input_disabled"
                      value={userInfo?.full_name || '-'}
                      readOnly
                    ></input>
                  </div>
                  <div className="form-input">
                    <label
                      htmlFor=""
                      className="form-input__label_account-info"
                    >
                      Email
                    </label>
                    <div className="relative">
                      <input
                        readOnly
                        className="form-input__input form-input__input-editable"
                        value={userInfo?.email || 'Chưa cập nhật'}
                      ></input>
                      <div
                        className="absolute right-4 top-[10px] cursor-pointer"
                        onClick={() => {
                          setIsShowUpdateEmailModal(true);
                        }}
                      >
                        <PenIcon />
                      </div>
                    </div>
                  </div>
                  <div className="form-input">
                    <label
                      htmlFor=""
                      className="form-input__label_account-info"
                    >
                      CCCD/CMND
                    </label>
                    <input
                      readOnly
                      className="form-input__input form-input__input_disabled"
                      value={userInfo?.cccd || '-'}
                    ></input>
                  </div>
                  <div className="form-input">
                    <label
                      htmlFor=""
                      className="form-input__label_account-info"
                    >
                      Nơi cấp
                    </label>
                    <input
                      readOnly
                      className="form-input__input form-input__input_disabled"
                      value={userInfo?.provided || '-'}
                    ></input>
                  </div>
                  <div className="form-input">
                    <label
                      htmlFor=""
                      className="form-input__label_account-info"
                    >
                      Địa chỉ thường trú
                    </label>
                    <textarea
                      name=""
                      id=""
                      cols={30}
                      rows={2}
                      className="form-input__input form-input__input_disabled hidden-scrollbar"
                      value={userInfo?.temporary_residence_address || '-'}
                      readOnly
                    ></textarea>
                  </div>
                </div>
                <div className="w-1/2 flex flex-col gap-2">
                  <div className="form-input">
                    <label
                      htmlFor=""
                      className="form-input__label_account-info"
                    >
                      Số điện thoại
                    </label>
                    <input
                      readOnly
                      className="form-input__input form-input__input_disabled"
                      value={userInfo?.phone_number || '-'}
                    ></input>
                  </div>
                  <div className="form-input">
                    <label
                      htmlFor=""
                      className="form-input__label_account-info"
                    >
                      Ngày sinh
                    </label>
                    <input
                      readOnly
                      className="form-input__input form-input__input_disabled"
                      value={
                        userInfo?.dob
                          ? dayjs(new Date(userInfo?.dob)).format('DD/MM/YYYY')
                          : '-'
                      }
                    ></input>
                  </div>
                  <div className="form-input">
                    <label
                      htmlFor=""
                      className="form-input__label_account-info"
                    >
                      Ngày cấp
                    </label>
                    <input
                      readOnly
                      className="form-input__input form-input__input_disabled"
                      value={
                        userInfo?.issued_date
                          ? dayjs(new Date(userInfo?.issued_date)).format(
                              'DD/MM/YYYY'
                            )
                          : '-'
                      }
                    ></input>
                  </div>
                  <div className="form-input">
                    <label
                      htmlFor=""
                      className="form-input__label_account-info"
                    >
                      Giới tính
                    </label>
                    <input
                      readOnly
                      className="form-input__input form-input__input_disabled"
                      value={
                        userInfo?.gender === 'F'
                          ? 'Nữ'
                          : userInfo?.gender === 'M'
                          ? 'Nam'
                          : '-'
                      }
                    ></input>
                  </div>
                  <div className="form-input">
                    <label
                      htmlFor=""
                      className="form-input__label_account-info"
                    >
                      Địa chỉ liên hệ
                    </label>
                    <textarea
                      name=""
                      id=""
                      cols={30}
                      rows={2}
                      className="form-input__input form-input__input_disabled hidden-scrollbar"
                      value={userInfo?.permanent_address || '-'}
                      readOnly
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center h-full">
              <AccountNotification />
            </div>
          )}
        </div>
        <div className="w-[38%] flex flex-col gap-2">
          <div className="box-items">
            <MultiCircleSupportBox
              sizeCircle_1="w-[44.22px] h-[44.22px]"
              sizeCircle_2="w-[75.28px] h-[75.28px]"
              sizeCircle_3="w-[112px] h-[112px]"
              colorCircle_1="bg-primary"
              colorCircle_2="bg-[#09395F]"
              colorCircle_3="bg-[#0B1D35]"
            >
              <RiPhoneFill color="#fff" className="w-[22.4px] h-[22.4px]" />
            </MultiCircleSupportBox>
            <div className="flex flex-col gap-4 mt-6">
              <p className="text-lg font-semibold leading-6">
                Hỗ trợ qua điện thoại
              </p>
              <p className="text-base font-normal leading-[22px] text-text_main_light">
                Bạn cần hỗ trợ trực tiếp, hãy gọi cho tổng đài của SBBS số
                <span className="font-bold text-white"> 19002648 </span> ngay
                nhé.
              </p>
            </div>
          </div>
          <div className="box-items">
            <MultiCircleSupportBox
              sizeCircle_1="w-[44.22px] h-[44.22px]"
              sizeCircle_2="w-[75.28px] h-[75.28px]"
              sizeCircle_3="w-[112px] h-[112px]"
              colorCircle_1="bg-primary"
              colorCircle_2="bg-[#09395F]"
              colorCircle_3="bg-[#0B1D35]"
            >
              <RiMailFill color="#fff" className="w-[22.4px] h-[22.4px]" />
            </MultiCircleSupportBox>
            <div className="flex flex-col gap-4 mt-6">
              <p className="text-lg font-semibold leading-6">
                Hỗ trợ qua email
              </p>
              <p className="text-base font-normal leading-[22px] text-text_main_light">
                Bạn cần hỗ trợ trực tiếp, hãy liên hệ với SBBS thông qua email
                <span className="font-bold text-white">
                  {' '}
                  info@sbbs.com.vn.{' '}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <UpdateEmailModel
        isShowUpdateEmailModel={isShowUpdateEmailModal}
        setIsShowUpdateEmailModel={setIsShowUpdateEmailModal}
        updateSuccess={() => {
          getUserData();
        }}
      />
      <VerifyAccountModal
        isOpen={isOpenVerifyAccountModal}
        closeModal={() => {
          setIsOpenVerifyAccountModal(false);
        }}
      />
    </>
  );
};

export default AccountPage;
