import React, { ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import {
  RiCheckboxCircleLine,
  RiCloseFill,
  RiInformationLine,
  RiLock2Line
} from 'react-icons/ri';
import { MultiCircleBox } from 'components/MultiCircleBox';

type ModalProps = {
  title: string;
  isOpen: boolean;
  closeModal: () => void;
  className?: string;
  icon?: ReactNode;
  children: ReactNode;
  hasCloseBtn?: boolean;
  closeBtnClassName?: string;
  subtitle?: string;
  subtitleClassName?: string;
  classNameTitle?: string;
  typePopup?: string;
  isBackground?: boolean;
  isCloseBackground?: boolean;
};

export const Modal = ({
  title,
  icon,
  subtitle,
  subtitleClassName,
  isOpen,
  closeModal,
  className,
  children,
  hasCloseBtn = false,
  closeBtnClassName,
  classNameTitle,
  typePopup,
  isBackground,
  isCloseBackground
}: ModalProps) => {
  const handleKeyDown = (event: any) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      return;
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[100]"
          onClose={() => {
            return;
          }}
          onClick={(e: any) => e.stopPropagation()}
          onKeyDown={handleKeyDown}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={`fixed inset-0 ${
                isBackground ? 'bg-[#0C111DB2]' : ''
              }`}
            />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            {isCloseBackground ? (
              <div
                className="absolute left-0 top-0 w-full h-full"
                onClick={() => closeModal()}
              ></div>
            ) : null}
            <div className="flex min-h-full items-center justify-center py-4 px-[70px] text-center bg-[#0C111DCC]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`${className} ${
                    typePopup === 'Success'
                      ? 'pt-[112px]'
                      : typePopup === 'none'
                      ? 'pt-6'
                      : 'pt-[88px]'
                  } border border-[#6E6E6E] relative w-full transform overflow-hidden rounded-xl bg-blackBlue p-6 text-left align-middle shadow-Shadow/xl transition-all`}
                >
                  {typePopup !== 'none' && (
                    <MultiCircleBox>
                      <span
                        className={`${
                          typePopup === 'Success'
                            ? 'border-[8px] border-darkGreen !rounded-[100%] bg-[#002500]'
                            : 'border border-[#98A2B3]'
                        }
                      w-[48px] h-[48px] flex justify-center items-center rounded-md`}
                      >
                        {icon ? (
                          icon
                        ) : (
                          <>
                            {typePopup === 'changePassword' ? (
                              <RiLock2Line className="text-[26px] text-[#98A2B3]" />
                            ) : typePopup === 'Success' ? (
                              <RiCheckboxCircleLine className="!text-[26px] text-[#00C802]" />
                            ) : (
                              <RiInformationLine className="text-[26px] text-[#98A2B3]" />
                            )}
                          </>
                        )}
                      </span>
                    </MultiCircleBox>
                  )}

                  {(title || subtitle || hasCloseBtn) && (
                    <Dialog.Title
                      as="h3"
                      className={`flex w-full text-lg font-semibold leading-6 text-white pb-2 `}
                    >
                      <div className={`w-full ${classNameTitle}`}>
                        {title}
                        <br />
                        {subtitle && (
                          <span
                            className={`text-green font-semibold text-[18px] ${subtitleClassName}`}
                          >
                            {subtitle}
                          </span>
                        )}
                      </div>
                      {hasCloseBtn && (
                        <button
                          type="button"
                          onClick={closeModal}
                          className={`absolute top-6 right-6 z-10 flex -mr-1 rounded-full items-center justify-center bg-F3F3F3 hover:bg-grey-drak ml-auto transition`}
                        >
                          <RiCloseFill
                            className={`text-[28px] ${closeBtnClassName}`}
                          />
                        </button>
                      )}
                    </Dialog.Title>
                  )}
                  <div className="">{children}</div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
