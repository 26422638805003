import React, { useState, useEffect } from 'react';
import { PasswordVisibilityToggle } from 'components/PasswordVisibilityToggle';
import { Button } from 'components/buttons/Button';
import { Modal } from 'components/modal/Modal';
import { AccountService } from 'core';

enum Steps {
  USER_NAME,
  CODE,
  NEW_PIN,
  SUCCESS
}

type ChangePinModelProps = {
  isShow: boolean;
  setIsShow: (isShow: boolean) => void;
};

const formatCountDown = (countDown: number) => {
  const minutes = Math.floor(countDown / 60);
  const seconds = countDown - minutes * 60;
  return `${minutes < 10 ? `0${minutes}` : minutes}:${
    seconds < 10 ? `0${seconds}` : seconds
  }`;
};

const ChangePinModel = ({ isShow, setIsShow }: ChangePinModelProps) => {
  const [step, setStep] = useState(Steps.USER_NAME);
  const [countDown, setCountDown] = useState(0);
  const [userName, setUserName] = useState('');
  const [code, setCode] = useState('');
  const [newPin, setNewPin] = useState('');
  const [reNewPin, setReNewPin] = useState('');
  const [error, setError] = useState('');
  const [errorRePin, setErrorRePin] = useState('');
  const [isShowPin, setIsShowPin] = useState(false);
  const [isShowRePin, setIsShowRePin] = useState(false);

  const handleCloseModal = () => {
    setIsShow(false);
    setError('');
    setCode('');
    setNewPin('');
    setReNewPin('');
    setIsShowPin(false);
    setStep(Steps.USER_NAME);
  };

  const checkPinIsNumber = (pin: string) => {
    return /^\d+$/.test(pin);
  };

  const validatePhoneNumber = (value: string) => {
    return /(0[3|5|7|8|9])+([0-9]{8})\b/g.test(value);
  };

  const handleStepChangePIN = async () => {
    if (newPin.length < 6 || !checkPinIsNumber(newPin)) {
      setError('Mã PIN không đúng định dạng. Vui lòng nhập lại.');
    } else if (newPin !== reNewPin) {
      setErrorRePin('Không trùng khớp. Vui lòng nhập lại');
    } else {
      const params = {
        phone_number: userName,
        pin: newPin
      };
      const res = await AccountService.pinForgotSubmit(params);
      if (res?.data?.status === 'Fail') {
        setError(res?.data?.error_message.split('|')[1]);
      } else {
        setError('');
        setStep(Steps.SUCCESS);
      }
    }
  };

  const getTitle = () => {
    switch (step) {
      case Steps.USER_NAME:
        return 'Xác minh danh tính';
      case Steps.NEW_PIN:
        return 'Đặt mã PIN mới';
      case Steps.CODE:
        return 'Mã xác minh';
      default:
        return '';
    }
  };

  const getDescription = () => {
    switch (step) {
      case Steps.USER_NAME:
        return 'Vui lòng nhập số điện thoại bạn đã đăng ký để nhận mã xác minh.';
      case Steps.CODE:
        return `Chúng tôi đã gửi mã xác minh đến ${userName}. Vui lòng nhập mã xác minh để tiếp tục.`;
      default:
        return '';
    }
  };

  const getTextButton = () => {
    switch (step) {
      case Steps.USER_NAME:
        return 'Gửi mã xác minh';
      case Steps.NEW_PIN:
        return 'Hoàn tất';
      default:
        return 'Tiếp tục';
    }
  };

  const handleCountDown = () => {
    setCountDown(countDown - 1);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (countDown > 0) {
      timeoutId = setTimeout(() => {
        handleCountDown();
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [countDown]);

  const reSendOTP = async () => {
    const res = await AccountService.verifyAccountOtp({
      phone_number: userName
    });
    if (res?.data?.status === 'Fail') {
      setError(res?.data?.error_message.split('|')[1]);
    } else {
      setCountDown(180);
      setError('');
    }
  };

  const handleNext = async () => {
    if (step === Steps.USER_NAME && userName) {
      if (!validatePhoneNumber(userName)) {
        setError('Số điện thoại không hợp lệ, vui lòng kiểm tra lại');
        return;
      }
      const res = await AccountService.verifyAccountOtp({
        phone_number: userName
      });
      if (res?.data?.status === 'Fail') {
        setError(res?.data?.error_message.split('|')[1]);
      } else {
        setCountDown(180);
        setError('');
        setStep(Steps.CODE);
      }
    }

    if (step === Steps.CODE && code) {
      const params = {
        phone_number: userName,
        otp: code
      };
      const res = await AccountService.confirmAccountOtp(params);
      if (res?.data?.status === 'Fail') {
        setError(res?.data?.error_message.split('|')[1]);
      } else {
        setError('');
        setStep(Steps.NEW_PIN);
      }
    }

    if (step === Steps.NEW_PIN) {
      handleStepChangePIN();
    }
  };

  const isDisableNext = () => {
    if (step === Steps.USER_NAME && !userName) {
      return true;
    }
  };

  return (
    <Modal
      isOpen={isShow}
      title={getTitle()}
      closeModal={() => {}}
      className={`max-w-[500px]`}
      typePopup={`${step === Steps.SUCCESS ? 'Success' : 'changePassword'}`}
    >
      <p className="text-sm mb-6">{getDescription()}</p>
      {step === Steps.USER_NAME && (
        <div>
          <div className="form-input relative">
            <label className="text-sm font-normal leading-5 required-after">
              Số điện thoại
            </label>
            <input
              type="text"
              placeholder="Nhập số điện thoại"
              className={`form-input__input ${
                error && 'form-input__input_error'
              }`}
              maxLength={10}
              value={userName}
              onChange={e => {
                setError('');
                setUserName(e.target.value);
              }}
            ></input>
            {error && (
              <div className="mt-[2px]">
                <p className="text-sm font-normal leading-5 text-carminePink">
                  {error}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      {step === Steps.CODE && (
        <div>
          <div className="form-input relative">
            <label htmlFor="" className="text-sm font-normal leading-5">
              Mã xác minh
            </label>
            <input
              type="text"
              placeholder={'Nhập mã xác minh'}
              className={`form-input__input ${
                error && 'form-input__input_error'
              }`}
              value={code}
              onChange={e => {
                if (e.target.value.length <= 6) {
                  setCode(e.target.value);
                  setError('');
                }
              }}
            ></input>
            {error && (
              <div className="mt-[2px]">
                <p className="text-sm font-normal leading-5 text-carminePink">
                  {error}
                </p>
              </div>
            )}
          </div>
          <div className="flex gap-10 text-sm mt-2">
            <div className="w-[157px]">
              Mã xác minh có hiệu lực trong{' '}
              <span className="font-semibold">
                {formatCountDown(countDown)}
              </span>
            </div>
            <div className="text-right flex-1">
              <div>Bạn chưa nhận được mã?</div>
              <button
                className="font-semibold text-primary"
                onClick={reSendOTP}
              >
                Gửi lại mã
              </button>
            </div>
          </div>
        </div>
      )}
      {step === Steps.NEW_PIN && (
        <div>
          <div className="form-input relative mb-6">
            <label htmlFor="" className="text-sm font-normal leading-5">
              Mã PIN mới
              <span className="text-carminePink">*</span>
            </label>
            <input
              type={isShowPin ? 'text' : 'password'}
              placeholder={'Nhập mã PIN mới'}
              className={`form-input__input ${
                error && 'form-input__input_error'
              }`}
              onChange={e => {
                if (e.target.value.length <= 6) {
                  setNewPin(e.target.value);
                  setError('');
                }
              }}
              value={newPin}
            ></input>
            <PasswordVisibilityToggle
              toggleVisibility={() => {
                setIsShowPin(o => !o);
              }}
              visible={isShowPin}
            />
            {error && (
              <div className="mt-[2px]">
                <p className="text-sm font-normal leading-5 text-carminePink">
                  {error}
                </p>
              </div>
            )}
          </div>
          <div className="form-input relative">
            <label htmlFor="" className="text-sm font-normal leading-5">
              Xác nhận mã PIN mới
              <span className="text-carminePink">*</span>
            </label>
            <input
              type={isShowRePin ? 'text' : 'password'}
              placeholder={'Nhập xác nhận mã PIN mới'}
              className={`form-input__input ${
                error && 'form-input__input_error'
              }`}
              onChange={e => {
                if (e.target.value.length <= 6) {
                  setReNewPin(e.target.value);
                  setError('');
                }
              }}
              value={reNewPin}
            ></input>
            <PasswordVisibilityToggle
              toggleVisibility={() => {
                setIsShowRePin(o => !o);
              }}
              visible={isShowRePin}
            />
            {errorRePin && (
              <div className="mt-[2px]">
                <p className="text-sm font-normal leading-5 text-carminePink">
                  {errorRePin}
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      {step === Steps.SUCCESS && (
        <div className="text-[20px] font-semibold mb-[98px]">
          Mã PIN của bạn đã được đặt lại thành công.
        </div>
      )}
      {step === Steps.SUCCESS && (
        <Button className="w-full" onClick={handleCloseModal}>
          Đóng
        </Button>
      )}
      {step !== Steps.SUCCESS && (
        <div
          className={`flex items-center gap-3 ${error ? 'mt-9' : 'mt-[48px]'}`}
        >
          <Button
            className="flex-1"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Đóng
          </Button>

          <Button
            variant="primary"
            className="flex-1"
            disabled={isDisableNext()}
            onClick={handleNext}
          >
            {getTextButton()}
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default ChangePinModel;
