import React, { useEffect, useState } from 'react';
import { Modal } from './Modal';
import { Button } from '../buttons/Button';
import { Countdown } from '../countdown';
import { ACTION_ORDER, ACTION_ORDER_TYPE } from 'core/constant';
import { RiInformationLine } from 'react-icons/ri';
import { sha256 } from 'js-sha256';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { decryptData } from 'core/utils';
import { ForgotPinButton } from '../ForgotPinButton';
import { AccountService } from 'core';
import { useAccount } from 'core/contexts/AccountContext';
import { toast } from 'react-toastify';
import { RiErrorWarningLine } from 'react-icons/ri';

type ModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

const SmartOtpGuideModal = ({ isOpen, closeModal }: ModalProps) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        title="Làm thế nào lấy mã Smart OTP?"
        classNameTitle="text-[20px]"
        closeModal={closeModal}
        className={`max-w-[550px]`}
      >
        <div className="pt-3">
          <ul className="flex flex-col gap-3 mb-8">
            <li>
              Bước 1: Vào ứng dụng{' '}
              <span className="font-semibold">SBBS iTrade</span> trên điện thoại
            </li>
            <li>
              Bước 2: Vào <span className="font-semibold">Cài đặt</span> chọn{' '}
              <span className="font-semibold">Smart OTP</span>
            </li>
            <li>
              Bước 3: Vào mục{' '}
              <span className="font-semibold">Quản lý tài khoản</span> và chọn
              tài khoản đã đăng ký
            </li>
            <li>Bước 4: Nhập mã OTP hiển thị trên màn hình</li>
          </ul>
          <Button
            className="w-full"
            variant="primary"
            size="large"
            onClick={() => closeModal()}
          >
            Đóng
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default SmartOtpGuideModal;
