import React, { useEffect, useState } from 'react';
import StockSearch from './StockSearch';
import StockSelectCommand from './StockSelectCommand';
import { InputNumber } from '../inputs/InputNumber';
import { StockOrderButton } from './StockOrderButton';
import { Modal, OrderModal, Button } from 'components';
import { formatCurrency, getOrderTypeByTime, parseTime } from 'core/utils';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAccount } from 'core/contexts/AccountContext';
import { MarketStockService, ConfigService } from 'core';
import { toast } from 'react-toastify';
import { RiCheckboxCircleFill, RiErrorWarningLine } from 'react-icons/ri';
import Slider from '@mui/material/Slider';
import { InfoTooltip } from 'components';
import {
  ACCOUNT_ID_TYPE,
  ACTION_ORDER,
  ACTION_ORDER_TYPE,
  STOCK_MARKET_ID
} from 'core/constant';
import { isHidePopupConfirm, checkSavePin } from 'core/utils';
import { InputPrice } from 'components/inputs/InputPrice';
import {
  IS_HOLIDAY_NEXT_DATE,
  IS_HOLIDAY_TODAY
} from 'core/constant/storage.constant';

const mockMarks = [
  {
    value: 0
  },
  {
    value: 100
  },
  {
    value: 200
  },
  {
    value: 300
  },
  {
    value: 400
  }
];

export const StockOrderForm = ({
  actionType,
  stockBuyingPower,
  stockData,
  stockInfo,
  priceStock,
  onSuccess
}: {
  actionType: ACTION_ORDER_TYPE;
  stockData?: any;
  stockInfo?: any;
  stockBuyingPower?: any;
  priceStock?: number;
  onSuccess: () => void;
}) => {
  const [params] = useSearchParams();
  const navigator = useNavigate();
  const symbolParam = params.get('symbol') as string;
  const [price, setPrice] = useState<number | string>();
  const [stockHolding, setStockHolding] = useState<any>();
  const [isShowInActiveStock, setIsShowInActiveStock] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderType, setOrderType] = useState<string>('');
  const [quanlity, setQuanlity] = useState<any>(0);
  const [showPIN, setShowPIN] = useState(false);
  const { account, userInfo } = useAccount();
  const [metadata, setMetadata] = useState<any>();
  const [systemConfig, setSystemConfig] = useState<any>();
  const is_holiday_today = localStorage.getItem(IS_HOLIDAY_TODAY)
    ? JSON.parse(localStorage.getItem(IS_HOLIDAY_TODAY)!)
    : false;
  const is_holiday_next_date = localStorage.getItem(IS_HOLIDAY_NEXT_DATE)
    ? JSON.parse(localStorage.getItem(IS_HOLIDAY_NEXT_DATE)!)
    : false;

  const marks =
    actionType === ACTION_ORDER.B.value && stockBuyingPower?.max_buy_qty
      ? [
          {
            value: 0
          },
          {
            value: Math.round(stockBuyingPower?.max_buy_qty / 4)
          },
          {
            value: Math.round((stockBuyingPower?.max_buy_qty / 4) * 2)
          },
          {
            value: Math.round((stockBuyingPower?.max_buy_qty / 4) * 3)
          },
          {
            value: Math.round(stockBuyingPower?.max_buy_qty)
          }
        ]
      : actionType === ACTION_ORDER.S.value && stockHolding?.quatity
      ? [
          {
            value: 0
          },
          {
            value: Math.round(stockHolding?.quatity / 4)
          },
          {
            value: Math.round((stockHolding?.quatity / 4) * 2)
          },
          {
            value: Math.round((stockHolding?.quatity / 4) * 3)
          },
          {
            value: Math.round(stockHolding?.quatity)
          }
        ]
      : mockMarks;

  const getMetadata = async () => {
    const data = await ConfigService.getMetaData();
    setMetadata(data);
  };

  const fetchSystemConfig = async () => {
    const data = await ConfigService.getSystemConfig();
    setSystemConfig(data);
  };

  useEffect(() => {
    getMetadata();
    fetchSystemConfig();
  }, []);

  useEffect(() => {
    if (priceStock) {
      setPrice(priceStock);
    }
  }, [priceStock]);

  const getDescription = (key: string) => {
    return metadata?.[key];
  };

  const getSystemConfig = (key: string) => {
    return systemConfig ? JSON.parse(systemConfig?.[key]) : '';
  };

  const handleSetPrice = (price: number) => {
    setPrice(price);
  };

  const getStepPrice = () => {
    const config = getSystemConfig('ORDER_STEP_PRICE');
    let step = 10;

    if (stockData?.instrument_type_id === 'ETF') {
      step = config?.ETF?.StepPrice || 10;
    } else {
      const stepConfigMarket = config?.Stock?.[stockData?.market_id];
      const stepPriceMarket = stepConfigMarket?.find((i: any) => {
        if (i?.Min < stockData?.last_price && stockData?.last_price < i?.Max) {
          return i;
        }
      });
      step = stepPriceMarket?.StepPrice || 10;
    }
    return step;
  };

  const validatePrice = (value: number) => {
    if (value * 1000 > stockInfo?.ceiling || value * 1000 < stockInfo?.floor) {
      return 'Giá không nằm trong biên độ';
    }

    const step = getStepPrice();

    const price = +(value * 1000).toFixed(2);

    if (price % step !== 0) {
      let msg = `Cổ phiếu ${stockData?.symbol} đang có bước giá là ${step}đ. Vui lòng nhập đúng bước giá này. Quý khách có thế nhập:`;

      let last = price - (price % step);
      for (let i = 0; i < 3; i++) {
        last = last + step;
        msg =
          msg +
          ' ' +
          (last / 1000).toLocaleString('en-US', {
            minimumFractionDigits: 2
          }) +
          (i === 2 ? '' : ',');
      }

      msg = msg + '...';
      return msg;
    }

    return null;
  };

  const fetchStockHolding = async () => {
    if (symbolParam && account) {
      const paramsData = {
        accountNo: account?.account_id,
        stockSymbol: symbolParam
      };
      const response = await MarketStockService.getStockHolding(paramsData);
      if (response?.data) {
        const data = response?.data?.data;
        setStockHolding(data);
      }
    }
  };

  useEffect(() => {
    fetchStockHolding();
  }, [symbolParam, account]);

  useEffect(() => {
    resetForm();
  }, [symbolParam]);

  useEffect(() => {
    if (!!stockData && !stockData?.active && !isShowInActiveStock) {
      setIsShowInActiveStock(true);
    }
  }, [stockData?.symbol]);

  const submit = async () => {
    const stockConfig = getSystemConfig('ORDER_EXCHANGE_MATCHING_TYPE');

    const now = new Date();
    let endOfDate = new Date();
    endOfDate.setHours(23, 59, 59);
    let startOfDate = new Date();
    startOfDate.setHours(0, 0, 0);
    let overTimeOrder = parseTime(stockConfig?.HOSE[5]?.FromTime);
    let startOpenTime = parseTime(stockConfig?.HOSE[0]?.FromTime);
    let stopOpenTime = new Date();

    if (stockData?.market_id !== STOCK_MARKET_ID.UPCOM) {
      stopOpenTime = parseTime(stockConfig?.HOSE[4]?.ToTime);
    } else {
      stopOpenTime = parseTime(stockConfig?.UPCOM[0]?.ToTime);
    }

    // 0h - thời gian bắt đầu phiên
    if (now >= startOfDate && now < startOpenTime) {
      // 1. Hôm nay và ngày mai là ngày nghỉ/ lễ
      // 2. Hôm nay không phải ngày nghỉ/lễ, ngày mai là ngày nghỉ/lễ
      if (
        (is_holiday_today && is_holiday_next_date) ||
        (is_holiday_today && !is_holiday_next_date)
      ) {
        toast(
          'Yêu cầu đặt lệnh ngoài giờ thất bại. Bạn vui lòng thử lại sau 1 ngày trước phiên giao dịch tiếp theo.',
          {
            type: 'error',
            icon: <RiErrorWarningLine className="text-[#F04438]" />
          }
        );
        return;
      }
    }
    // Thời gian kết thúc phiên - 20h
    // 1. hôm nay và ngày mai không phải ngày nghỉ/ lễ
    // 2. Hôm nay không phải ngày nghỉ/lễ, ngày mai là ngày nghỉ/lễ
    // 3. Hôm nay và ngày mai là ngày nghỉ/ lễ
    // 4. Hôm nay là ngày nghỉ/lễ, ngày mai không phải là ngày nghỉ/lễ
    else if (now >= stopOpenTime && now < overTimeOrder) {
      if (
        (!is_holiday_today && !is_holiday_next_date) ||
        (!is_holiday_today && is_holiday_next_date) ||
        (is_holiday_today && is_holiday_next_date) ||
        (is_holiday_today && !is_holiday_next_date)
      ) {
        toast(
          'Yêu cầu đặt lệnh ngoài giờ thất bại. Bạn vui lòng thử lại sau 20:00.',
          {
            type: 'error',
            icon: <RiErrorWarningLine className="text-[#F04438]" />
          }
        );
        return;
      }
    }
    // 20h - 24h
    // 1. Hôm nay không phải ngày nghỉ/lễ, ngày mai là ngày nghỉ/lễ
    // 2. Hôm nay và ngày mai là ngày nghỉ/ lễ
    else if (now >= overTimeOrder && now < endOfDate) {
      if (
        (!is_holiday_today && is_holiday_next_date) ||
        (is_holiday_today && is_holiday_next_date)
      ) {
        toast(
          'Yêu cầu đặt lệnh ngoài giờ thất bại. Bạn vui lòng thử lại sau 1 ngày trước phiên giao dịch tiếp theo.',
          {
            type: 'error',
            icon: <RiErrorWarningLine className="text-[#F04438]" />
          }
        );
        return;
      }
    }

    if (orderType === 'LO') {
      if (
        !price ||
        typeof price !== 'number' ||
        (typeof price === 'number' && validatePrice(price))
      ) {
        toast('Giá đặt không hợp lệ.', {
          type: 'error',
          icon: <RiErrorWarningLine className="text-[#F04438]" />
        });
        return;
      }
    }
    if (
      !quanlity || quanlity > stockInfo?.lot_size
        ? quanlity % stockInfo?.lot_size !== 0
        : false
    ) {
      if (quanlity % stockInfo?.lot_size !== 0 && orderType === 'LO') {
        toast('Khối lượng đặt của lệnh lô lẻ từ 1 đến 99', {
          type: 'error',
          icon: <RiErrorWarningLine className="text-[#F04438]" />
        });
      } else {
        toast('Khối lượng không hợp lệ.', {
          type: 'error',
          icon: <RiErrorWarningLine className="text-[#F04438]" />
        });
      }
      return;
    }

    if (
      actionType === ACTION_ORDER.B.value &&
      quanlity > stockBuyingPower?.max_buy_qty
    ) {
      toast('Khối lượng lớn hơn khối lượng tối đa có thể mua.', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />
      });
      return;
    }

    if (
      actionType === ACTION_ORDER.S.value &&
      quanlity > stockHolding?.quatity
    ) {
      toast('Khối lượng lớn hơn khối lượng tối đa có thể bán.', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />
      });
      return;
    }

    const pinSaved = await isHidePopupConfirm();
    if (pinSaved) {
      await submitOrder(pinSaved);
      return;
    }
    setShowPIN(true);
  };

  const resetForm = () => {
    setPrice(0);
    setQuanlity(0);
    setOrderType('LO');
  };

  const submitOrder = async (
    pin: string,
    type?: 'PIN' | 'SMART_OTP' | 'SMS_OTP',
    isCheckSavePinCode?: boolean
  ) => {
    if (pin) {
      setLoading(true);
      const priceData =
        orderType === 'LO'
          ? typeof price === 'number'
            ? (price * 1000).toFixed(2)
            : price
          : stockData?.last_price;
      let paramsdata: any = {
        account_no: account?.account_id,
        matching_type: orderType,
        stock_symbol: symbolParam,
        price: priceData,
        quantity: quanlity,
        factor_authen_enum_type: type,
        note: '',
        pin: pin
      };

      if (type === 'SMART_OTP' || type === 'SMS_OTP') {
        paramsdata.otp = pin;
        paramsdata.pin = '';
      }

      if (actionType === ACTION_ORDER.S.value) {
        const res = await MarketStockService.sellStock(
          userInfo?.user_id || '',
          paramsdata
        );
        const data = res?.data;

        if (data?.status === 'Fail') {
          setLoading(false);

          toast(data?.error_message.split('|')[1] || data?.error_message, {
            type: 'error',
            icon: <RiErrorWarningLine className="text-[#F04438]" />
          });
          return;
        }

        if (data?.data) {
          toast(
            'Yêu cầu đặt lệnh đã được tiếp nhận. Quý khách vui lòng kiểm tra trạng thái lệnh trong sổ lệnh.',
            {
              type: 'success',
              icon: <RiCheckboxCircleFill className="text-[#00C802]" />
            }
          );
          resetForm();
          setShowPIN(false);
          onSuccess();
        }
        if (isCheckSavePinCode && type === 'PIN') {
          await checkSavePin(pin);
        }
        setLoading(false);
      }

      if (actionType === ACTION_ORDER.B.value) {
        const res = await MarketStockService.buyStock(
          userInfo?.user_id || '',
          paramsdata
        );
        const data = res?.data;

        if (isCheckSavePinCode && type === 'PIN') {
          await checkSavePin(pin);
        }

        if (data?.status === 'Fail') {
          setLoading(false);

          toast(data?.error_message.split('|')[1] || data?.error_message, {
            type: 'error',
            icon: <RiErrorWarningLine className="text-[#F04438]" />
          });
          return;
        }
        if (data?.data) {
          toast(
            'Yêu cầu đặt lệnh đã được tiếp nhận. Quý khách vui lòng kiểm tra trạng thái lệnh trong sổ lệnh.',
            {
              type: 'success',
              icon: <RiCheckboxCircleFill className="text-[#00C802]" />
            }
          );
          resetForm();
          setShowPIN(false);
          onSuccess();
        }

        setLoading(false);
      }
    }
  };

  const disabledIncrease = () => {
    if (actionType === ACTION_ORDER.S.value) {
      if (stockHolding?.quatity === 0 || stockHolding?.quatity === quanlity) {
        return true;
      }
    }
  };

  const isDisableOrderType = (orderType: string): boolean => {
    const configType = getSystemConfig('ORDER_EXCHANGE_MATCHING_TYPE');
    const orderTypeByMarket = configType[stockData?.market_id];
    if (!orderTypeByMarket) {
      return true;
    }
    const orderTypeByTime = getOrderTypeByTime(orderTypeByMarket);
    const available = orderTypeByTime.includes(orderType);
    if (available) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div className="p-4 flex flex-col gap-3">
        <div className="bg-yankeesBlue flex rounded-[6px]">
          <StockOrderButton
            type={ACTION_ORDER.B.value}
            isActive={actionType === ACTION_ORDER.B.value}
            onClick={() => {
              navigator(
                `/stock-order?symbol=${symbolParam}&action=${ACTION_ORDER.B.value}`
              );
            }}
            className="flex-1"
          />
          <StockOrderButton
            type={ACTION_ORDER.S.value}
            isActive={actionType === ACTION_ORDER.S.value}
            onClick={() => {
              navigator(
                `/stock-order?symbol=${symbolParam}&action=${ACTION_ORDER.S.value}`
              );
            }}
            className="flex-1"
          />
        </div>
        <StockSearch />
        <div className="flex flex-col gap-2">
          <StockSelectCommand />
          {stockData?.market_id !== STOCK_MARKET_ID.UPCOM ? (
            <div className="flex gap-2">
              {stockData?.market_id === STOCK_MARKET_ID.HNX ? (
                <button
                  className={`flex-1 px-1 py-[5px] text-xs font-semibold text-center rounded-sm cursor-pointer disabled:opacity-50 ${
                    orderType === 'MTL' ? 'bg-primary' : 'bg-yankeesBlue'
                  }`}
                  disabled={isDisableOrderType('MTL')}
                  onClick={() => setOrderType('MTL')}
                >
                  MTL
                </button>
              ) : null}
              {stockData?.market_id === STOCK_MARKET_ID.HNX ? (
                <button
                  className={`flex-1 px-1 py-[5px] text-xs font-semibold text-center rounded-sm cursor-pointer disabled:opacity-50 ${
                    orderType === 'MOK' ? 'bg-primary' : 'bg-yankeesBlue'
                  }`}
                  disabled={isDisableOrderType('MOK')}
                  onClick={() => setOrderType('MOK')}
                >
                  MOK
                </button>
              ) : null}
              {stockData?.market_id === STOCK_MARKET_ID.HNX ? (
                <button
                  className={`flex-1 px-1 py-[5px] text-xs font-semibold text-center rounded-sm cursor-pointer disabled:opacity-50 ${
                    orderType === 'MAK' ? 'bg-primary' : 'bg-yankeesBlue'
                  }`}
                  disabled={isDisableOrderType('MAK')}
                  onClick={() => setOrderType('MAK')}
                >
                  MAK
                </button>
              ) : null}

              {stockData?.market_id === STOCK_MARKET_ID.HOSE ? (
                <button
                  className={`flex-1 px-1 py-[5px] text-xs font-semibold text-center rounded-sm cursor-pointer disabled:opacity-50 ${
                    orderType === 'ATO' ? 'bg-primary' : 'bg-yankeesBlue'
                  }`}
                  disabled={isDisableOrderType('ATO') || is_holiday_next_date}
                  onClick={() => setOrderType('ATO')}
                >
                  ATO
                </button>
              ) : null}

              {stockData?.market_id === STOCK_MARKET_ID.HOSE ||
              stockData?.market_id === STOCK_MARKET_ID.HNX ? (
                <button
                  className={`flex-1 px-1 py-[5px] text-xs font-semibold leading-[18px] text-center rounded-sm cursor-pointer disabled:opacity-50 ${
                    orderType === 'ATC' ? 'bg-primary' : 'bg-yankeesBlue'
                  }`}
                  disabled={isDisableOrderType('ATC')}
                  onClick={() => setOrderType('ATC')}
                >
                  ATC
                </button>
              ) : null}

              {stockData?.market_id === STOCK_MARKET_ID.HOSE ? (
                <button
                  className={`flex-1 px-1 py-[5px] text-xs font-semibold leading-[18px] text-center rounded-sm cursor-pointer disabled:opacity-50 ${
                    orderType === 'MP' ? 'bg-primary' : 'bg-yankeesBlue'
                  }`}
                  disabled={isDisableOrderType('MP')}
                  onClick={() => setOrderType('MP')}
                >
                  MP
                </button>
              ) : null}

              {stockData?.market_id === STOCK_MARKET_ID.HNX ? (
                <button
                  className={`flex-1 px-1 py-[5px] text-xs font-semibold leading-[18px] text-center rounded-sm cursor-pointer disabled:opacity-50 ${
                    orderType === 'PLO' ? 'bg-primary' : 'bg-yankeesBlue'
                  }`}
                  disabled={isDisableOrderType('PLO')}
                  onClick={() => setOrderType('PLO')}
                >
                  PLO
                </button>
              ) : null}
              <button
                className={`flex-1 px-1 py-[5px] text-xs font-semibold leading-[18px] text-center rounded-sm cursor-pointer disabled:opacity-50 ${
                  orderType === 'LO' ? 'bg-primary' : 'bg-yankeesBlue'
                }`}
                disabled={isDisableOrderType('LO') || is_holiday_next_date}
                onClick={() => {
                  setOrderType('LO');
                }}
              >
                LO
              </button>
            </div>
          ) : null}
        </div>
        <div className="flex flex-col gap-2">
          <InputNumber
            value={quanlity}
            disabledIncrease={disabledIncrease()}
            onChange={v => setQuanlity(v)}
            stepSize={stockInfo?.lot_size || 100}
            placeholder="Khối lượng"
            type={orderType === 'LO' ? 'price' : 'quanlity'}
          />
          {quanlity > (stockInfo?.lot_size || 100) && orderType === 'LO'
            ? quanlity % (stockInfo?.lot_size || 100) !== 0 && (
                <span className="text-sm text-lowPriceColor">
                  Khối lượng đặt của lệnh lô lẻ từ 1 đến 99
                </span>
              )
            : ''}
          <div className="flex justify-between">
            <div className="flex items-center gap-[4px]">
              <span className="text-sm">KL tối đa</span>
              <InfoTooltip
                title={getDescription('MTA_ORDER_TOOLTIP_MAXIMUM_VOLUME')}
                color="text-gray_dark"
              />
            </div>
            <div
              className="flex items-center gap-[2px] cursor-pointer"
              onClick={() => {
                setQuanlity(
                  actionType === ACTION_ORDER.S.value
                    ? stockHolding?.quatity || 0
                    : stockBuyingPower?.max_buy_qty || 0
                );
              }}
            >
              {actionType === ACTION_ORDER.S.value ? (
                <span className="text-sm text-lowPriceColor">
                  {stockHolding?.quatity
                    ? (stockHolding?.quatity).toLocaleString('en-US')
                    : 0}
                </span>
              ) : (
                <span className="text-sm text-high-price">
                  {stockBuyingPower?.max_buy_qty
                    ? (stockBuyingPower?.max_buy_qty).toLocaleString('en-US')
                    : '0'}
                </span>
              )}
            </div>
          </div>
        </div>
        {orderType !== 'LO' && (
          <div className="rounded-md border border-gray flex overflow-hidden">
            <button
              className="block bg-yankeesBlue p-2 h-[38px] w-10 rounded-tl-sm rounded-bl-sm flex items-center justify-center"
              disabled={true}
            >
              <span className="block w-[15px] h-[2px] bg-cadetGrey"></span>
            </button>
            <input
              value={orderType}
              className="bg-[transparent] p-2 flex-1 text-center text-sm placeholder:text-cadetGrey"
              onFocus={() => setOrderType('LO')}
            />

            <button
              className="block bg-yankeesBlue p-2 h-[38px] w-10 rounded-tr-sm rounded-br-sm flex items-center justify-center"
              disabled={true}
            >
              <span
                className="
                  relative block w-[14px] h-[2px] bg-cadetGrey
                  after:block after:h-[14px] after:w-[2px] after:bg-cadetGrey after:absolute after:bottom-1/2 after:right-1/2 after:translate-x-1/2 after:translate-y-1/2
                "
              ></span>
            </button>
          </div>
        )}
        {orderType === 'LO' && (
          <div>
            <InputPrice
              value={!!price ? Number(price) : 0}
              onChange={v => {
                setPrice(v);
              }}
              initValue={
                stockData?.last_price ? stockData?.last_price / 1000 : 0
              }
              type="price"
              placeholder="Giá"
              stepSize={getStepPrice()}
            />
            {price && typeof price === 'number' && !!validatePrice(price) ? (
              <div className="text-sm text-error mt-1">
                {validatePrice(price)}
              </div>
            ) : null}
          </div>
        )}
        {account?.account_type_id === ACCOUNT_ID_TYPE.MARGIN ||
        account?.account_type_id === ACCOUNT_ID_TYPE.MARGIN_N ? (
          <div className="flex justify-between">
            <div className="flex items-center gap-[4px]">
              <span className="text-sm">Tỷ lệ hỗ trợ</span>
              <InfoTooltip
                title={getDescription('MTA_ORDER_TOOLTIP_MARGIN_RATE')}
              />
            </div>
            <div className="text-sm">
              {(stockBuyingPower?.margin_ratio || 0) * 100}%
            </div>
          </div>
        ) : null}

        <div className="flex justify-between">
          <div className="flex items-center gap-[4px]">
            <span className="text-sm">Sức mua</span>
            <InfoTooltip
              title={getDescription('MTA_ORDER_TOOLTIP_PURCHASING_ABILITY')}
              color="text-gray_dark"
            />
          </div>
          <div className="text-sm">
            {formatCurrency(stockBuyingPower?.buying_power)}
          </div>
        </div>

        <div className="relative py-[2px] mt-4">
          <Slider
            className="slider-stock"
            value={quanlity}
            step={stockInfo?.lot_size}
            min={0}
            onChange={(e: any) => {
              setQuanlity(e?.target?.value || 0);
            }}
            valueLabelFormat={`${
              quanlity
                ? Math.floor((quanlity / stockBuyingPower?.max_buy_qty) * 100)
                : 0
            }%`}
            valueLabelDisplay="on"
            marks={marks}
            max={
              actionType === ACTION_ORDER.B.value
                ? stockBuyingPower?.max_buy_qty
                : stockHolding?.quatity || 400
            }
          />
        </div>
        <Button
          variant="primary"
          className=""
          onClick={() => submit()}
          disabled={!stockData?.active}
        >
          Đặt lệnh
        </Button>
        <div className="flex justify-between">
          <div className="flex items-center gap-[4px]">
            <span className="text-sm">Giá trị lệnh tạm tính</span>
          </div>
          <div className="text-sm">
            {orderType === 'LO'
              ? Math.floor(
                  Number(price || 0) * 1000 * (quanlity || 0)
                ).toLocaleString('en-US')
              : Math.floor(
                  (stockData?.last_price || 0) * (quanlity || 0)
                ).toLocaleString('en-US')}
          </div>
        </div>
      </div>
      <OrderModal
        isOpen={showPIN}
        loading={loading}
        title="Xác nhận đặt lệnh"
        stockInfo={{
          action: actionType,
          symbol: symbolParam,
          price: orderType === 'LO' ? price : orderType,
          quanlity: quanlity || 0
        }}
        closeModal={() => setShowPIN(false)}
        onSubmit={(pin, type) => {
          if (!price && orderType === 'LO') {
            toast('Giá đặt không hợp lệ.', {
              type: 'error',
              icon: <RiErrorWarningLine className="text-[#F04438]" />
            });
            return;
          }
          if (!quanlity) {
            toast('Khối lượng không hợp lệ.', {
              type: 'error',
              icon: <RiErrorWarningLine className="text-[#F04438]" />
            });
            return;
          }
          submitOrder(pin, type, true);
        }}
      />
      <Modal
        isOpen={isShowInActiveStock}
        title="Thông báo"
        hasCloseBtn
        closeModal={() => setIsShowInActiveStock(false)}
        className={`max-w-[640px]`}
      >
        <p className="text-sm font-normal leading-[22px] text-text_main_light">
          Cổ phiếu này đã tạm ngưng giao dịch.
        </p>
      </Modal>
    </>
  );
};
