import React, { Fragment, useState, useEffect } from 'react';
import sbbs_logo from 'assets/images/sbbs_logo.png';
import {
  BellIcon,
  SearchIcon,
  HammerIcon,
  ArrowDown
} from 'assets/icons/index';
import { Button } from 'components/buttons/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RiAccountCircleFill, RiLogoutCircleFill } from 'react-icons/ri';
import { Menu, Transition } from '@headlessui/react';
import { useAccount } from 'core/contexts/AccountContext';
import {
  ACTION_ORDER_TYPE,
  AccountService,
  ConfigService,
  FirestoreService,
  MarketStockService
} from 'core';
import { SelectIcon } from 'assets/icons';
import { getAccountShortDescription, getAccountName } from 'core/utils';
import useComponentVisible from 'core/hooks/UseComponentVisible';
import StockOptionSelect from '../StockOrder/StockOptionSelect';
import { StockOrderPopup } from 'components/StockOrder/StockOrderPopup';
import { onSnapshot } from 'firebase/firestore';
import DEModal from 'components/modal/DEModal';
import {
  IS_HOLIDAY_NEXT_DATE,
  IS_HOLIDAY_TODAY
} from 'core/constant/storage.constant';
import dayjs from 'dayjs';

import { RES_HOLIDAY_TYPE, RES_STATUS } from 'core/constant';

const randomColor = () => {
  const colorArr = [
    '#FF7337',
    '#F4D942',
    '#0BA5EC',
    '#FF7337',
    '#F4D942',
    '#0BA5EC'
  ];
  const random = Math.floor(Math.random() * colorArr.length);
  return colorArr[random];
};

const NavBar = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [symbol, setSymbol] = useState('');
  const { account, accountInfo, updateAccount } = useAccount();
  const { userInfo, updateUserInfo } = useAccount();

  const [isShowStockOrder, setIsShowStockOrder] = useState(false);
  const [actionType, setActionType] = useState<ACTION_ORDER_TYPE>('B');
  const [stockData, setStockData] = useState<any>();
  const [stockBuyingPower, setStockBuyingPower] = useState<any>();
  const [stockInfo, setStockInfo] = useState<any>();
  const [orderBook, setOrderBook] = useState<any>();
  const [symbolParam, setSymbolParam] = useState('');
  const [isShowDEModal, setIsShowDEModal] = useState(false);

  const deStatusStart = localStorage.getItem('DE_START');
  const deStatusEnd = localStorage.getItem('DE_END');

  const getUserData = async () => {
    const res = await AccountService.getUserInfo();
    if (res?.data?.status === 'Success') {
      updateUserInfo(res?.data?.data);
    }
  };

  const initStock = async () => {
    let responseStocks = await FirestoreService.getListVNINDEX();
    let data: any = responseStocks?.docs.map((docSnapshot: any) =>
      docSnapshot.data()
    );
    localStorage.setItem('STOCKS', JSON.stringify(data));
  };

  useEffect(() => {
    if (!userInfo) {
      getUserData();
    }
    initStock();
  }, []);

  const getFistLetterName = (name: string) => {
    if (name) {
      const words = name.split(' ');
      const lastWord = words[words.length - 1];
      return lastWord?.charAt(0)?.toUpperCase();
    }
    return '';
  };

  const fetchStockBuyingPower = async () => {
    if (stockData && account) {
      const paramsStock = {
        accountNo: account?.account_id,
        stockSymbol: stockData?.symbol,
        exchange: stockData?.market_id,
        price: stockData?.last_price
      };
      const response = await MarketStockService.getStockBuyingPower(
        paramsStock
      );
      if (response?.data) {
        const data = response?.data?.data;
        setStockBuyingPower(data);
      }
    }
  };

  const fetchStockInfo = async () => {
    if (symbolParam) {
      const response = await MarketStockService.getStockInfo(symbolParam);
      if (response?.data) {
        const data = response?.data?.data;
        setStockInfo(data);
      }
    }
  };

  const fetchOrderBook = async () => {
    if (symbolParam && account) {
      const fromDate = dayjs(new Date().setHours(0, 0, 0)).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      const toDate = dayjs(new Date().setHours(23, 59, 59)).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      const paramsData = {
        accountNo: account?.account_id,
        pageSize: 100,
        fromDate: fromDate,
        toDate: toDate
      };
      const response = await MarketStockService.getOrderBook(paramsData);
      if (response?.data) {
        const data = response?.data?.data;
        setOrderBook(data);
      }
    }
  };

  const checkHoliday = async () => {
    const date = new Date();
    const toDate = dayjs(date).format('YYYY-MM-DD');
    const nextDate = dayjs(date).add(1, 'days').format('YYYY-MM-DD');
    let params = {
      date: nextDate
    };

    const res = await ConfigService.check_holiday(params);
    if (res?.status === RES_STATUS.SUCCESS) {
      if (res?.data?.is_holiday) {
        if (res?.data?.type === RES_HOLIDAY_TYPE.CEREMORNY) {
          localStorage.setItem(IS_HOLIDAY_NEXT_DATE, JSON.stringify(true));
        } else {
          localStorage.setItem(IS_HOLIDAY_NEXT_DATE, JSON.stringify(false));
        }
      } else {
        localStorage.setItem(IS_HOLIDAY_NEXT_DATE, JSON.stringify(false));
      }
    }

    params = {
      date: toDate
    };

    const res2 = await ConfigService.check_holiday(params);
    if (res2?.status === RES_STATUS.SUCCESS) {
      if (res2?.data?.is_holiday) {
        if (res2?.data?.type === RES_HOLIDAY_TYPE.CEREMORNY) {
          localStorage.setItem(IS_HOLIDAY_TODAY, JSON.stringify(true));
        } else {
          localStorage.setItem(IS_HOLIDAY_TODAY, JSON.stringify(false));
        }
      } else {
        localStorage.setItem(IS_HOLIDAY_TODAY, JSON.stringify(false));
      }
    }
  };

  useEffect(() => {
    setStockBuyingPower(undefined);
    if (symbolParam) {
      const unsubscribe = onSnapshot(
        FirestoreService.getStockBySymbol(symbolParam),
        (querySnapshot: any) => {
          const data: any = querySnapshot?.docs.map((docSnapshot: any) =>
            docSnapshot.data()
          );
          if (data?.length > 0) {
            setStockData(data[0]);
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
      setIsShowStockOrder(true);

      return () => {
        unsubscribe();
      };
    }
  }, [symbolParam]);

  useEffect(() => {
    fetchStockBuyingPower();
  }, [account]);

  useEffect(() => {
    fetchStockInfo();
  }, [symbolParam]);

  useEffect(() => {
    if (!stockBuyingPower) {
      fetchStockBuyingPower();
    }
  }, [stockData, account]);

  useEffect(() => {
    checkHoliday();
  }, []);

  const handleShowDEStatus = async () => {
    const res = await ConfigService.getDEStatus();
    if (res?.status === 'Success') {
      if (res?.data?.system_performing_day_end) {
        if (deStatusStart === 'hasShown') {
          if (deStatusEnd === 'hasShown') {
            setIsShowDEModal(true);
            localStorage.setItem('DE_END', '');
          } else {
            setIsShowDEModal(false);
          }
        } else {
          setIsShowDEModal(true);
          localStorage.setItem('DE_START', 'hasShown');
        }
      } else {
        if (deStatusEnd === 'hasShown') {
          setIsShowDEModal(false);
        } else {
          if (deStatusStart === 'hasShown') {
            setIsShowDEModal(true);
            localStorage.setItem('DE_END', 'hasShown');
          }
        }
      }
    }
  };

  useEffect(() => {
    handleShowDEStatus();
  }, []);

  return (
    <div className="flex justify-between items-center px-4 h-[70px] bg-black">
      <a href="/" title="SBBS">
        <img src={sbbs_logo} alt="SBBS Logo" className="max-h-[52px]" />
      </a>
      <div className="flex justify-center items-center gap-4">
        <BellIcon />
        <div ref={ref} className="relative">
          <div className="absolute top-[10px] left-4">
            <SearchIcon className="w-5 h-5" />
          </div>
          <input
            type="text"
            className="search-input w-[327px] h-10"
            placeholder="Tìm kiếm cổ phiếu"
            onFocus={() => {
              setSymbol('');
              setIsComponentVisible(true);
            }}
            onChange={e => {
              setSymbol(e.target.value);
            }}
          />
          {isComponentVisible && (
            <StockOptionSelect
              symbol={symbol}
              onSelectItem={v => {
                setIsComponentVisible(false);
                searchParams.append('stock', v);
                setSearchParams(searchParams);
              }}
            />
          )}
        </div>

        <Button
          className="font-semibold"
          variant="secondary"
          onClick={() => {
            setIsShowStockOrder(!isShowStockOrder);
          }}
        >
          <HammerIcon active={true} type="secondary" />
          <span>Đặt lệnh</span>
        </Button>

        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button>
            <Button variant="secondary" className="font-semibold min-w-[91px]">
              <p>{getAccountName(account?.account_type_id)}</p>
              <ArrowDown />
            </Button>
          </Menu.Button>
          {accountInfo && accountInfo?.accounts.length > 0 ? (
            <Transition
              as={Fragment}
              enter="transition duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-1"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-1"
              leaveTo="opacity-0"
            >
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="absolute right-0 top-[55px] w-[209px] bg-yankeesBlue px-4 py-2 rounded-md menu-profile">
                  {accountInfo?.accounts.map((item: any) => (
                    <div
                      className={`p-2 flex gap-2 relative text-gray rounded-md cursor-pointer ${
                        account?.account_type_id === item?.account_type_id
                          ? 'isSelectStyle'
                          : ''
                      }`}
                      key={item?.account_type_id}
                      onClick={() => updateAccount(item)}
                    >
                      <div className="min-w-[40px] h-10 rounded shadow-Shadow/xs flex justify-center items-center bg-gray command-code">
                        <p className="text-yankeesBlue text-sm font-semibold leading-4 text-center">
                          {item?.account_type_id}
                        </p>
                      </div>
                      <div className="flex flex-col gap-1 ">
                        <p className="text-sm font-semibold leading-5">
                          {getAccountName(item?.account_type_id)}
                        </p>
                        <p className="line-clamp-2 text-xs font-normal leading-[18px]">
                          {getAccountShortDescription(item?.account_type_id)}
                        </p>
                      </div>
                      {account?.account_type_id === item?.account_type_id ? (
                        <div className="absolute right-2 top-2">
                          <SelectIcon />
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </Transition>
            </Transition>
          ) : null}
        </Menu>
        <Menu as="div" className="relative inline-block">
          <Menu.Button>
            {userInfo?.avatar ? (
              <img
                src={userInfo?.avatar}
                alt="Avatar"
                className="w-10 h-10 rounded-full"
              />
            ) : (
              <div
                className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer`}
                style={{ backgroundColor: randomColor() }}
              >
                <p className="text-[22.5px] font-bold tracking-[-0.138px] text-[#000]">
                  {getFistLetterName(userInfo?.full_name || '')}
                </p>
              </div>
            )}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className="absolute right-0 top-[55px] w-[209px] bg-yankeesBlue px-4 py-2 rounded-md menu-profile z-40">
              <ul>
                <li
                  className="flex items-center gap-2 my-2 cursor-pointer"
                  onClick={() => {
                    navigate('/account');
                  }}
                >
                  <RiAccountCircleFill className="text-lg" />
                  <span className="text-sm flex-1">Thông tin tài khoản</span>
                </li>
                <li
                  className="flex items-center gap-2 my-2 cursor-pointer"
                  onClick={() => {
                    navigate('/logout');
                  }}
                >
                  <RiLogoutCircleFill />
                  <span className="text-sm flex-1">Đăng xuất</span>
                </li>
              </ul>
            </div>
          </Transition>
        </Menu>
      </div>
      {isShowStockOrder && (
        <div className="absolute right-0 top-[70px] bottom-0 animation-slide-left">
          <StockOrderPopup
            type={actionType}
            stockBuyingPower={stockBuyingPower}
            stockData={stockData}
            stockInfo={stockInfo}
            onClose={() => setIsShowStockOrder(false)}
            onSuccess={() => {
              fetchOrderBook();
              fetchStockBuyingPower();
            }}
            onChangeActionType={(type: ACTION_ORDER_TYPE) =>
              setActionType(type)
            }
            onChaneSymbolParam={(symbol: string) => setSymbolParam(symbol)}
            symbolParam={symbolParam}
          />
        </div>
      )}

      <DEModal
        isOpen={isShowDEModal}
        title={`${
          deStatusStart && (deStatusEnd == '' || !deStatusEnd)
            ? 'Hệ thống đang chạy tác vụ'
            : 'Hệ thống SBBS hoàn thành tác vụ'
        }`}
        content={`${
          deStatusStart && (deStatusEnd == '' || !deStatusEnd)
            ? 'Hệ thống đang trong quá trình chạy tác vụ cuối ngày và dự kiến hoàn thành trong 2 tiếng. Thông tin về Danh mục tài sản và các tính năng truy xuất dữ liệu và giao dịch tiền có thể tạm thời gián đoạn. Kinh mong Quý khách thông cảm!'
            : 'Hệ thống đã hoàn thành tác vụ cuối ngày. Quý khách có thể truy cập và sử dụng ứng dụng SBBS iTrade.'
        }`}
        closeModal={() => {
          setIsShowDEModal(false);
        }}
      />
    </div>
  );
};

export default NavBar;
