import React, { useState, useEffect } from 'react';
import { useAccount } from 'core/contexts/AccountContext';
import { AccountNotification, TitleSection } from 'components';
import { AssetsService, AccountService } from 'core/services';
import { formatCurrency } from 'core/utils';
import StockPortfolio from './components/StockPortfolio';
import AssetsPieChart from './components/AssetsPieChart';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { ComingSoonFeature } from 'components';
import { ACCOUNT_ID_TYPE } from 'core/constant';

enum ASSETS_TAB {
  STOCK,
  BONDS,
  DERIVATIV,
  FUND,
  CASH,
  ASSET
}

const AssetsPage = () => {
  const [assetTab, setAssetTab] = useState(ASSETS_TAB.STOCK);
  const { account, updateWatchlist } = useAccount();
  const [totalAssets, setTotalAssets] = useState<any>();
  const [isShowValue, setIsShowValue] = useState(false);

  const fetchTotalAssets = async () => {
    const res = await AssetsService.getTotalAssets(account?.account_id || '');
    const data = res?.data?.data;
    setTotalAssets(data);
  };

  const getFavoriteSymbol = async () => {
    const response = await AccountService.getwatchlist();
    if (response?.data) {
      const data = response?.data?.data;
      updateWatchlist(data);
    }
  };

  useEffect(() => {
    getFavoriteSymbol();
  }, []);

  useEffect(() => {
    if (account) {
      fetchTotalAssets();
    }
  }, [account]);

  const renderContentDetail = () => {
    switch (assetTab) {
      case ASSETS_TAB.STOCK:
        return (
          <div className="pt-4">
            <div className="md:flex gap-10 p-4 bg-[#101828] mb-4 text-sm w-fit">
              <div>
                Giá thị trường:{' '}
                <span className="pl-1">
                  {formatCurrency(totalAssets?.stock_amount)}đ
                </span>
              </div>
              <div className="flex">
                <p>Lãi/lỗ:</p>
                <div
                  className={`pl-1 flex gap-[6px] ${
                    totalAssets?.total_profit_amount < 0
                      ? 'text-lowPriceColor'
                      : 'text-high-price'
                  }`}
                >
                  <p>
                    {totalAssets?.total_profit_amount > 0 ? '+' : ''}
                    {formatCurrency(totalAssets?.total_profit_amount)}đ
                  </p>
                  <p>{`(${totalAssets?.total_profit_percent > 0 ? '+' : ''}${
                    totalAssets?.total_profit_percent
                      ? totalAssets?.total_profit_percent
                      : 0
                  }%)`}</p>
                </div>
              </div>
            </div>
            <StockPortfolio />
          </div>
        );
      case ASSETS_TAB.ASSET:
        return (
          <>
            {account ? (
              <div className="pr-5 py-4 max-w-[756px] md:flex md:items-center xl:gap-10">
                <AssetsPieChart />
              </div>
            ) : (
              <div className="h-full flex justify-center items-center">
                <AccountNotification />
              </div>
            )}
          </>
        );
      // case ASSETS_TAB.CASH:
      //   return <CashPortfolio />;
      default:
        return (
          <div className="p-4 text-center">
            <ComingSoonFeature />
          </div>
        );
    }
  };

  return (
    <div className="flex flex-col h-[100%] w-full p-2">
      <div className="border-l border-r border-b border-tab_background flex flex-col bg-black rounded-lg">
        <TitleSection title="Tổng quan tài sản" />
        <div className="flex p-4">
          <div className="flex-1">
            <div className="mb-2 flex items-center gap-1">
              <span className="text-sm">Tổng tài sản</span>
              <button
                onClick={() => {
                  setIsShowValue(o => !o);
                }}
              >
                {isShowValue ? <RiEyeLine /> : <RiEyeOffLine />}
              </button>
            </div>
            <div className="text-base font-medium">
              {account
                ? isShowValue
                  ? `${formatCurrency(totalAssets?.total_asset_amount)}đ`
                  : '*****************'
                : '-'}
            </div>
          </div>
          <div className="flex-1">
            <div className="text-sm mb-2">Tổng lời/lỗ</div>
            <div
              className={`text-base font-medium ${
                totalAssets?.total_profit_amount > 0
                  ? 'text-high-price'
                  : totalAssets?.total_profit_amount < 0
                  ? 'text-lowPriceColor'
                  : 'text-mustardYellow'
              }`}
            >
              {account ? (
                isShowValue ? (
                  <>
                    {totalAssets?.total_profit_amount > 0 ? '+' : ''}
                    {formatCurrency(totalAssets?.total_profit_amount)}đ{` `}(
                    {totalAssets?.total_profit_percent > 0 ? '+' : ''}
                    {totalAssets?.total_profit_percent}%)
                  </>
                ) : (
                  '*****************'
                )
              ) : (
                '-'
              )}
            </div>
          </div>
          <div className="flex-1">
            <div className="text-sm mb-2">Lời/lỗ trong ngày</div>
            <div
              className={`text-base font-medium ${
                totalAssets?.intraday_total_profit_amount > 0
                  ? 'text-high-price'
                  : totalAssets?.intraday_total_profit_amount < 0
                  ? 'text-lowPriceColor'
                  : 'text-mustardYellow'
              }`}
            >
              {account ? (
                isShowValue ? (
                  <>
                    {totalAssets?.intraday_total_profit_amount > 0 ? '+' : ''}
                    {formatCurrency(totalAssets?.intraday_total_profit_amount)}đ
                    {` `}(
                    {totalAssets?.intraday_total_profit_percent > 0 ? '+' : ''}
                    {totalAssets?.intraday_total_profit_percent}%)
                  </>
                ) : (
                  '*****************'
                )
              ) : (
                '-'
              )}
            </div>
          </div>
          <div className="flex-1">
            {account?.account_type_id === ACCOUNT_ID_TYPE.MARGIN ||
            account?.account_type_id === ACCOUNT_ID_TYPE.MARGIN_N ? (
              <>
                <div className="text-sm mb-2">Tổng nợ</div>
                <div className="text-base font-medium">
                  {account ? (isShowValue ? '-' : '*****************') : '-'}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>

      <div className="border-l border-r border-b border-tab_background flex flex-1 flex-col rounded-lg bg-black">
        <TitleSection title="Chi tiết tài sản" />
        {account ? (
          <div className="p-4">
            <div className="flex gap-4 lg:gap-5 xl:gap-[30px]">
              <button
                className={`text-base font-semibold relative pb-[6px] border-b ${
                  assetTab === ASSETS_TAB.STOCK
                    ? 'border-primary text-primary'
                    : 'border-[transparent]'
                }`}
                onClick={() => setAssetTab(ASSETS_TAB.STOCK)}
              >
                Cổ phiếu
              </button>

              {/* <button
                className={`text-base font-semibold relative pb-[6px] border-b ${
                  assetTab === ASSETS_TAB.BONDS
                    ? 'border-primary text-primary'
                    : 'border-[transparent]'
                }`}
                onClick={() => setAssetTab(ASSETS_TAB.BONDS)}
              >
                Trái phiếu
              </button>
              <button
                className={`text-base font-semibold relative pb-[6px] border-b ${
                  assetTab === ASSETS_TAB.DERIVATIV
                    ? 'border-primary text-primary'
                    : 'border-[transparent]'
                }`}
                onClick={() => setAssetTab(ASSETS_TAB.DERIVATIV)}
              >
                Phái sinh
              </button>
              <button
                className={`text-base font-semibold relative pb-[6px] border-b ${
                  assetTab === ASSETS_TAB.FUND
                    ? 'border-primary text-primary'
                    : 'border-[transparent]'
                }`}
                onClick={() => setAssetTab(ASSETS_TAB.FUND)}
              >
                Chứng chỉ quỹ
              </button> */}
              <button
                className={`text-base font-semibold relative pb-[6px] border-b ${
                  assetTab === ASSETS_TAB.CASH
                    ? 'border-primary text-primary'
                    : 'border-[transparent]'
                }`}
                onClick={() => setAssetTab(ASSETS_TAB.CASH)}
              >
                Tiền
              </button>
              <button
                className={`text-base font-semibold relative pb-[6px] border-b ${
                  assetTab === ASSETS_TAB.ASSET
                    ? 'border-primary text-primary'
                    : 'border-[transparent]'
                }`}
                onClick={() => setAssetTab(ASSETS_TAB.ASSET)}
              >
                Phân bổ tài sản
              </button>
            </div>
            {renderContentDetail()}
          </div>
        ) : (
          <div className="mt-[104px]">
            <AccountNotification isHideButton={false} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetsPage;
